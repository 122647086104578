import style from "./000_Content.module.css"
import a1 from "./Pic/e_District_1.jpg"

const A0_UP_eDistrict=()=>{
    document.title="Uttar Pradesh जाति,आय, निवास प्रमाण पत्र Online Apply| Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Apply Online eDistrict UP जाति,आय, निवास प्रमाण पत्र OR Check Status and Download जाति,आय, निवास प्रमाण पत्र Online</h1>
            
             <h2><b style={{color:"red"}}>Post-Name :-</b>Apply Online eDistrict UP जाति,आय, निवास प्रमाण पत्र OR Check Status and Download Online</h2>
             <h2><b style={{color:"red"}}>State Name :- </b>Bihar</h2>
             <h2><b style={{color:"red"}}>During Process :- </b>2-5 Days</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>Friends, today we will tell you how to apply for RTPS Bihar Caste, Income, Resident Certificate online in a very simple way at home, and in this you will not need to give any OTP or go to the block, you will get your caste, income, resident certificate sitting at home. Will go, going to tell about it. So don't miss even a single step. For this, read this article till the end.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Apply eDistrict UP जाति,आय, निवास प्रमाण पत्र Online</h1>
                    <h3>(Uttar Pradesh eDistrict (Uttar Pradesh))</h3>
                    <h2>Apply, Download or Check Status जाति,आय, निवास प्रमाण पत्र Online</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Www.JobForSarkari.Com</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Scheme Started Date :-</b> NA</li></p>
                        
                        <p><li><b>Last Date For Apply :-</b> NA</li></p>
                       
                    </div>
                    <div>
                              
                        <h1>Application Fee</h1>  
                        <p><li><b>जाति प्रमाणपत्र:-</b> 30/-</li></p>
                        <p><li><b>आय प्रमाणपत्र :-</b> 30/-</li></p> 
                        <p><li><b>निवास प्रमाणपत्र :-</b> 30/-</li></p> 
                        <p><li><b>दिव्यांग प्रमाणपत्र :-</b> 0/-</li></p> 
                        <p><li><b>हैसियत प्रमाणपत्र :-</b> 120/-</li></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>eligible for New Pan card</h1>
                        <p><li><b>Nationality :-</b>Be an Indian citizen</li></p>
                        <p><li><b>Place of Birth  :-</b>Be a permanent resident of Uttar Pradesh</li></p>
                       
                    </div>
                    
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit</h5></h1>  
                        <p><li><b>Minimum Age :-</b>NA</li></p> 
                        <p><li><b>Maximum Age :-</b>NA</li></p> 
                                  
                    </div>
                </div>
                <div className={style.p4} > <div className={style.q1} style={{borderRight:"2px solid black"}}> <h5>Certificate  Name</h5></div> <div className={style.q3}> <h5>Some Information</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1} style={{borderRight:"2px solid black"}}> जाति प्रमाणपत्र</div> 
                    <div className={style.q3} > <li></li>जाति प्रमाण पत्र एक महत्वपूर्ण दस्तावेज है जो अनुसूचित जाति (SC), अनुसूचित जनजाति (ST), और अन्य पिछड़ा वर्ग (OBC) के व्यक्तियों को विभिन्न सरकारी लाभों और योजनाओं में सहायता करता है।</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1} style={{borderRight:"2px solid black"}}> आय प्रमाण पत्र</div> 
                    <div className={style.q3} > <li></li>आय प्रमाण पत्र (Income Certificate) एक महत्वपूर्ण दस्तावेज है, जो किसी व्यक्ति की वार्षिक आय का प्रमाण देता है। यह प्रमाण पत्र विभिन्न सरकारी योजनाओं, स्कॉलरशिप, बैंक लोन, और अन्य लाभों के लिए आवश्यक होता है। उत्तर प्रदेश सरकार ने आय प्रमाण पत्र बनवाने की प्रक्रिया को आसान बनाने के लिए इसे ऑनलाइन और ऑफलाइन दोनों तरीकों से उपलब्ध किया है।</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1} style={{borderRight:"2px solid black"}}> निवास प्रमाण पत्र</div> 
                    <div className={style.q3} > <li></li>निवास प्रमाण पत्र जिसे डोमिसाइल सर्टिफिकेट भी कहा जाता है, उत्तर प्रदेश में रहने वाले व्यक्तियों के लिए एक महत्वपूर्ण दस्तावेज है। यह प्रमाण पत्र कई सरकारी सेवाओं, योजनाओं और लाभों का लाभ उठाने के लिए आवश्यक होता है। यह शैक्षणिक संस्थानों में दाखिला, सरकारी नौकरियों में आवेदन, संपत्ति खरीदने और अन्य सरकारी योजनाओं में अनिवार्य रूप से मांगा जाता है।</div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Respective Documents Needed For Respective Modification :-</h1>
                    <h3><li> स्वप्रमाणित घोषणा पत्र</li></h3>
                    <h3><li>राशन कार्ड की छाया प्रति / बिजली का बिल</li></h3>
                    <h3><li>वोटर पहचान पत्र की छाया प्रति</li></h3>
                    <h3><li>यदि शिक्षा प्राप्त कर रहा है तो शैक्षणिक प्रमाण पत्र</li></h3>
                    <h3><li> आधार कार्ड</li></h3>
                    <h3><li>निवास प्रमाण पत्र</li></h3>
                    <h3><li>पासपोर्ट साइज फोटो</li></h3>
                    <h3><li>सक्रिय मोबाइल नंबर</li></h3>
                    <h3><li>ईमेल आईडी</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Needed Mobile Or Laptop To Apply, Download जाति, आय, निवासी प्रमाण पत्र</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2> Apply Online जाति</h2></div>
                        <div><a href="https://esathi.up.gov.in/citizenservices/login/login.aspx?Xpr$lgnp=6uFDOBDtU6cRt1TA38G7fvnkHNxBD6qT" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online आय</h2></div>
                        <div><a href="https://esathi.up.gov.in/citizenservices/login/login.aspx?Xpr$lgnp=6uFDOBDtU6cRt1TA38G7fvnkHNxBD6qT" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online निवास</h2></div>
                        <div><a href="https://esathi.up.gov.in/citizenservices/login/login.aspx?Xpr$lgnp=6uFDOBDtU6cRt1TA38G7fvnkHNxBD6qT" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Application Status</h2></div>
                        <div><a href="https://edistrict.up.gov.in/edistrictup/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Certificate</h2></div>
                        <div><a href="https://esathi.up.gov.in/citizenservices/login/login.aspx?Xpr$lgnp=6uFDOBDtU6cRt1TA38G7fvnkHNxBD6qT" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://edistrict.up.gov.in/edistrictup/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default A0_UP_eDistrict