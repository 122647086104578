import style from "./000_Content.module.css"
import a1 from "./Pic/Railway_RPF_1.jpg"

const Railway_RPF=()=>{
    document.title="Railway RPF Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Railway RPF Constable / Sub Inspector Online Form 2024</h1>  
             <h2><b style={{color:"red"}}>Post-Name :-</b>Railway RPF Constable / Sub Inspector Recruitment</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>15/04/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>14/05/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The Railway Protection Force, or RPF, will soon make public the formal announcement on the recruitment process for the positions of Constable and Sub Inspector, according to the most recent media updates (Short Notice).The notification posted on job news states that online applications for the Railway RPF Constable / Sub Inspector Recruitment 2024 Exam will be accepted starting on April 15, 2024, and closing on May 14, 2024. Additionally, the test for this recruitment is probably going to take place in June or July; the official notification will include the date.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Railway RPF Constable / Sub Inspector Online Form 2024</h1>
                    <h3>(Railway Protection Force (RPF))</h3>
                    <h2>Post Name – Railway RPF Constable / Sub Inspector Recruitment</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 4660</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 15/04/2024</li></p>
                        <p><li><b>Last Date :-</b> 14/05/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 14/05/2024</li></p>
                        <p><li><b>Exam Date Paper I :-</b>Available Soon</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 500/-</li></p>            
                        <p><li><b>Sc/St :-</b> 250/-</li></p> 
                        <p><li><b>Female(All Category) :-</b> 250/-</li></p>
                        <p><li><b>PH(Disability) :-</b> 250/-</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 01/07/2024</h5></h1>  
                        <p><h3 style={{color:"blue"}}>Sub-Inspector :-</h3></p>
                        <p><li><b>Minimum Age :-</b> 20 Years</li></p> 
                        <p><li><b>Maximum Age :-</b> 28 Years</li></p>  
                        <p><h3 style={{color:"blue"}}>Constable :-</h3></p>
                        <p><li><b>Minimum Age :-</b> 18 Years</li></p> 
                        <p><li><b>Maximum Age :-</b> 28 Years</li></p>   
                        <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> Sub-Inspector</div> 
                    <div className={style.q2}> 452</div> 
                    <div className={style.q3}> <li></li>Applicants for the aforementioned recruitment must hold a bachelor's degree in any field from an accredited university in India.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Constable</div> 
                    <div className={style.q2}> 4208</div> 
                    <div className={style.q3}> <li></li>Applicants for the aforementioned recruitment must have passed their class 10th or high school examination from any recognized Indian board.</div>
                </div>
                
                <div id={style.p5}>
                    <h1>Details of the Railway RPF Constable/Sub Inspector Recruitment (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- Railway RPF Constable/Sub Inspector Recruitment</h1>
                     <h2>Category Wise Vacancy Details 2024 :-</h2>
                     
                     <h3><li>Sub-Inspector :– 452 Posts</li></h3>
                     <h3><li>Constable :– 4208 Posts</li></h3>
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th,12th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://www.rrbapply.gov.in/#/auth/landing" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://rpf.indianrailways.gov.in/RPF/PDF/Upcoming.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.adda247.com/jobs/rpf-si-syllabus/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://prepp.in/rpf-si-exam/practice-papers" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=IclWO13YK2g&t=197s&ab_channel=SarkariNDNbyJabiRZone" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://rpf.indianrailways.gov.in/RPF/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div  style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div  style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default Railway_RPF