import style from "./Navcss.module.css"
import { Link } from "react-router-dom"

const Job=()=>{
    document.title="Home | Job For Sarkari"
    return(
        <div id={style.j}>
        <div id={style.job}>
    
            <div className={style.job1}><Link to="/bpsctre1" style={{textDecoration:"none"}}><p className={style.job3}><h2>BPSC TRE 3.0</h2> <h2>Result</h2></p></Link> </div>
            <div className={style.job2}><Link to="/ibpspomt" style={{textDecoration:"none"}}><p className={style.job3}><h2>IBPS PO/ MT XIV</h2> <h2>Score Card</h2></p></Link></div>
            <div className={style.job1}><Link to="/sscchsl" style={{textDecoration:"none"}}><p className={style.job3}><h2>IBPS PO_MT Mains</h2> <h2>Admit Card</h2></p></Link></div>
            <div className={style.job2}><Link to="/bpsc1" style={{textDecoration:"none"}}><p className={style.job3}><h2>BPSC 70th</h2> <h2>Pre Admit Card</h2></p></Link></div>
        </div>
        <div id={style.job}>
    
            <div className={style.job2}><Link to="/railwayrpf" style={{textDecoration:"none"}}><p className={style.job3}><h2>MPPSC Pre</h2> <h2>Apply Online</h2></p></Link></div>
            <div className={style.job1}><Link to="/sbiclerk1" style={{textDecoration:"none"}}><p className={style.job3}><h2>SBI Clerk</h2> <h2>Apply Online</h2></p></Link></div>
            <div className={style.job2}><Link to="/rajasthantgtteacher1" style={{textDecoration:"none"}}><p className={style.job3}><h2>RPSC TGT Teacher</h2> <h2>Apply Online</h2></p></Link></div>
            <div className={style.job1}><Link to="/upscengineeringservices" style={{textDecoration:"none"}}><p className={style.job3}><h2>UPSC Eng Services</h2> <h2>Result</h2></p></Link></div>
        </div>
     </div>
    )
}

export default Job