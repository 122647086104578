import style from "./Navcss.module.css"
import img from "./Image/logo.jpg"
const Logo=()=>{
    return(
        <div id={style.logo}>
            <div className={style.l1} ></div>
             
             <div id={style.ll}><div className={style.k1}><img src={img} alt="img"  id={style.img}/></div><div className={style.l2}><h1><b>JOB FOR SARKARI</b></h1> <h2>Www.JobForSarkari.Com</h2><h1>हमारी वेबसाइट पर आपका स्वागत है</h1></div><div className={style.k3}></div></div>
            
            
            <div className={style.l1}></div>
        </div>
    )
}

export default Logo