import style from "./000_Content.module.css"
import a1 from "./Pic/ISRO_VSSC_1.jpg"

const ISRO_VSSC_Recruitment=()=>{
    document.title="ISRO VSSC Recruitment | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>ISRO VSSC Recruitment Various Post Online Form 2023</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> ISRO VSSC Recruitment 2023</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>02/05/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>16/05/2023</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>According to the official updates, ISRO-Vikram Sarabhai Space Center (VSSC) had published the notification for the recruitment of Technical Assistant, Scientific Assistant & Library Assistant Posts on the official website of the ISRO VSSC. As per the notification, Online application process for ISRO VSSC Technical Assistant Recruitment 2023 has started from 02 May 2023. There is a total of 63 vacancies has been issued in the ISRO VSSC Recruitment 2023 for Technical Assistant, Scientific Assistant & Library Assistant Posts. Candidates must check the complete details for ISRO VSSC Recruitment 2023 which has given below.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>ISRO VSSC Recruitment 2023</h1>
                    <h3>(ISRO-Vikram Sarabhai Space Center (VSSC))</h3>
                    <h2>Post Name – Technical Assistant, Scientific Assistant & Library Assistant</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 63</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 02/05/2023</li></p>
                        <p><li><b>Last Date :-</b> 16/05/2023</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 16/05/2023</li></p>
                        <p><li><b>Exam Date :-</b>  Available Soon</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>          
                        <p><li><b>General / OBC :- </b> 750/-</li></p>  
                        <p><li><b>SC / ST :-</b> 750/-/-</li></p> 
                        <p><li><b>PH(Disability) :-</b> 750/-/-</li></p>    
                          
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Any Where In India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 01/01/2020</h5></h1>  
                        
                        <p><li><b>Minimum Age :-</b> Not Specified</li></p> 
                        <p><li><b>Maximum Age :-</b> 35 Years</li></p>   
                        <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> Technical Assistant</div> 
                    <div className={style.q2}> 60</div> 
                    <div className={style.q3}> <li></li>Candidates who have First Class Diploma Degree in Related Trade from a Recognized University will be eligible for this recruitment.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Scientific Assistant</div> 
                    <div className={style.q2}> 02</div> 
                    <div className={style.q3}> <li></li>Candidates who have First Class Bachelor’s Degree in Chemistry will be eligible for this recruitment.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Library Assistant</div> 
                    <div className={style.q2}> 01</div> 
                    <div className={style.q3}> <li></li>Candidates who have First Class Master’s degree in Library Science / Library & Information Science thers from a recognized University will be eligible for this recruitment.</div>
                </div>
                <div id={style.p5}>
                    <h1>Vacancy Details of ISRO VSSC Recruitment (2023) </h1>
                     
                     <div>
                     <h1>Post Name :- Technical Assistant, Scientific Assistant & Library Assistant</h1>
                     <h2>Posts Wise Vacancy Details :-</h2>
                     
                     <h3><li>Technical Assistant – 60 Posts</li></h3>
                     <h3><li>Scientific Assistant – 02 Posts</li></h3>
                     <h3><li>Library Assistant – 01 Posts</li></h3>

                     <h2>Discipline Wise Vacancy Details :-</h2>
                     <h2>{`1.)`}Technical Assistant :-</h2>
                     <h3><li>(Post Code : 1479) Electronics – 24 Posts</li></h3>
                     <h3><li>(Post Code : 1480) Mechanical – 20 Posts</li></h3>
                     <h3><li>(Post Code : 1481) Computer Science – 06 Posts</li></h3>
                     <h3><li>(Post Code : 1482) Chemical – 05 Posts</li></h3>
                     <h3><li>(Post Code : 1483) Cvil – 03 Posts</li></h3>
                     <h3><li>(Post Code : 1484) Refrigeration & AC – 01 Posts</li></h3>
                     <h3><li>(Post Code : 1485) Automobile – 01 Posts</li></h3>
                     
                     <h2>{`2.)`}Scientific Assistant :-</h2>
                     <h3><li>(Post Code : 1486) Chemistry – 02 Posts</li></h3>

                     <h2>{`3.)`}Library Assistant :-</h2>
                     <h3><li>(Post Code : 1487) Library Assistant ‘A’ – 01 Posts</li></h3>
                     
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts Degree Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://rmt.vssc.gov.in/RMT323/applyV1.jsp" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://www.vssc.gov.in/careers.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.vssc.gov.in/careers.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.vssc.gov.in/previousQuestions.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=bwfdJGi6B-M&ab_channel=A24Knowledge" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.vssc.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default ISRO_VSSC_Recruitment