import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/Railway_Job.jpg"

const A001_Railway_job=()=>{
    document.title="Railway Jobs | Job For Sarkari"
    return(
        <div id={style.list}>
        <h1>Railway Jobs</h1>
        <h3>New Job issued on 15 November 2024</h3>
        <li><Link to="/railwayassistantlocopilot1" style={{textDecoration:"none"}}><b className={style.a}>RRB(Railway Recruitment Board) Assistant Loco Pilot ALP Recruitment, Admit Card 2024</b></Link></li>
        <h3>New Job issued on 02 March 2024</h3>
        <li><Link to="/railwayrpf" style={{textDecoration:"none"}}><b className={style.a}>Railway RPF Constable / Sub Inspector Apply Online Form 2024</b></Link></li>
        <div><img src={img} alt="" style={{width:"100%", height:"400px"}} /></div>
        </div>
    )
}
export default A001_Railway_job