import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/New_Job.jpg"

const A00__Newjob=()=>{
    document.title="New Job | Job For Sarkari"
    return(
        <div id={style.list}>
            <h1>New Job</h1>
            <h3>New Job issued on 01 January 2025 </h3>
            <li><Link to="/mppsc1" style={{textDecoration:"none"}}><b className={style.a}>Madhya Pradesh State Service Exam Online Form 2025</b></Link></li>
            <h3>New Job issued on 26 December 2024</h3>
            <li><Link to="/rajasthantgtteacher1" style={{textDecoration:"none"}}><b className={style.a}>Rajasthan RPSC Senior Teacher Grade II TGT Teacher Recruitment Apply Online 2024 </b></Link></li>
            
            <h3>New Job issued on 15 December 2024</h3>
            <li><Link to="/sbiclerk1" style={{textDecoration:"none"}}><b className={style.a}>SBI (State Bank of India) Junior Associates Clerk Online Form 2024</b></Link></li>
            
            <h3>New Job issued on 12 April 2024</h3>
            <li><Link to="/armytgc" style={{textDecoration:"none"}}><b className={style.a}>Army TGC 140 January 2025 Batch Apply Online Form 2024</b></Link></li>
            <li><Link to="/biharpolytechnic" style={{textDecoration:"none"}}><b className={style.a}>Bihar Polytechnic Apply Online Form 2024</b></Link></li>
            <li><Link to="/upsciesiss" style={{textDecoration:"none"}}><b className={style.a}>UPSC IES / ISS Apply Online Form 2024</b></Link></li>

            <h3>New Job issued on 02 March 2024</h3>
            <li><Link to="/railwayrpf" style={{textDecoration:"none"}}><b className={style.a}>Railway RPF Constable / Sub Inspector Apply Online Form 2024</b></Link></li>
           
            <h3>New Job issued on 10 April 2024</h3>
            <li><Link to="/upsccombinedmedical" style={{textDecoration:"none"}}><b className={style.a}>UPSC Combined Medical Services Apply Online Form 2024</b></Link></li>
           
            <h3>New Job issued on 07 April 2024</h3>
            <li><Link to="/ctet" style={{textDecoration:"none"}}><b className={style.a}>CTET July 2024 Correction / Edit Form for Paper I & Paper II 2024</b></Link></li>
            <li><Link to="/sscchsl" style={{textDecoration:"none"}}><b className={style.a}>SSC 10+2 CHSL Recruitment Online Form 2024</b></Link></li>

            <h3>New Job issued on 14 December 2023</h3>
            <li><Link to="/biharstet" style={{textDecoration:"none"}}><b className={style.a}>Bihar State Eligibility Test BSEB STET Apply Online Form for Paper I and II (2024)</b></Link></li>
      
            <h3>New Job issued on 24 November 2023</h3>
            <li><Link to="/sscgd" style={{textDecoration:"none"}}><b className={style.a}>SSC GD Contable Recruitment Online Form 2023</b></Link></li>
      
            <h3>New Job issued on 09 November 2023</h3>
            <li><Link to="/biharpolicebpsscsubinspector" style={{textDecoration:"none"}}><b className={style.a}>Bihar Police BPSSC Sub Inspector Recruitment 2023 Re Upload Photo Signature</b></Link></li>
            
            <h3>New Job issued on 1 July 2023</h3>
            <li><Link to="/ibpsclerkxii" style={{textDecoration:"none"}}><b className={style.a}>IBPS Clerk XIII Recruitment 2023</b></Link></li>
            
            <h3>New Job issued on 02 May 2023</h3>
            <li><Link to="/isrovssc" style={{textDecoration:"none"}}><b className={style.a}>ISRO VSSC Recruitment Various Post Online Form 2023</b></Link></li>
            
            <h3>New Job issued on 29 April 2023</h3>
            <li><Link to="/ncertnontechnical" style={{textDecoration:"none"}}><b className={style.a}>NCERT Non Teaching Recruitment  Various Post Online Form 2023</b></Link></li>
            <div><img src={img} alt="" style={{width:"100%", height:"400px"}} /></div>
        </div>
    )
}

export default A00__Newjob