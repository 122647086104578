import style from "./000_Content.module.css"
import a1 from "./Pic/UPPSC_Staff_Nurse_1.jpg"

const UPPSC_Staff_Nurse=()=>{
    document.title="UPPSC Staff Nurse Admit Card | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>UPPSC Staff Nurse Admit Card 2023</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>UPPSC Staff Nurse Recruitment 2023</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>21/08/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>29/09/2023</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>An examination post notification for staff nurses (male and female) has been released by the Uttar Pradesh Public Service Commission (UPPSC) for 2023. Candidates who have been enrolled and there is a vacancy may download the admit card. Read the advertisement and apply after learning about the recruitment process, salary range, age restriction, selection process, job requirements, and other facts.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>UPPSC Staff Nurse Admit Card (2023)</h1>
                    <h3>(Uttar Pradesh Public Service Commission (UPPSC))</h3>
                    <h2>Post Name – UPPSC Staff Nurse Recruitment 2023</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 2240</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 21/08/2023</li></p>
                        <p><li><b>Last Date :-</b> 29/09/2023</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 29/09/2023</li></p>
                        <p><li><b>Admit Card :-</b> 08/12/2023</li></p> 
                        <p><li><b>Exam Date :-</b> 19/12/2023</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 125/-</li></p>
                        <p><li><b>SC/ST :-</b> 65</li></p> 
                        <p><li><b>PH(Disability) :-</b> 25</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Respective State</p>          
                        
                    </div>
                    <div>
                    <h1>Age Limit</h1>  
                        <p><li><b>Minimum Age :-</b>21 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>40</li></p> 
                        <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> UPPSC Staff Nurse</div> 
                    <div className={style.q2}> 2240</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Class 10th with Science and 10+2 Intermediate Exam with Diploma in General Nursing and Midwifery OR B.SC Nursing Degree</li><li>Registration Certificate in UP Nursing Council</li><li> Read the Notification For More Eligibility </li></div>
                </div>
               
                <div id={style.p5}>
                    <h1>Vacancy Details for UPPSC Staff Nurse Recruitment (2023) </h1>
                     
                     <div>
                     <h1>Post Name :- UPPSC Staff Nurse Recruitment 2023.</h1>
                    <h2>Gender Wise Vacancy Details 2023 :-</h2>
                     <h3><li>Staff Nurse Male :– 171 Posts</li></h3>
                     <h3><li>Staff Nurse Female :– 2069 Posts</li></h3>
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th&12th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Download Admit Card</h2></div>
                        <div><a href="https://uppsc.up.nic.in/CandidatePages/OTRAppForm/Applicant_OtrAuthentication.aspx?WEqGEem9m0+8Y4tNQqJiNYurmbKsjJR/fgSlThSTh6o=#no-back" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Exam Notice</h2></div>
                        <div><a href="https://uppsc.up.nic.in/Open_PDF.aspx?I4PnQ0tBagkze5MUNzAf2FxrdGvhPjoS" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://uppsc.up.nic.in/CandidatePages/Notifications.aspx" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Date Extended Notice</h2></div>
                        <div><a href="https://uppsc.up.nic.in/Open_PDF_DB.aspx?I4PnQ0tBagnjkwBgn49V9awDjJuxibwb" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://uppsc.up.nic.in/OuterPages/View_Enclosure.aspx?ID=726&flag=E&FID=778" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/blog/uppsc-staff-nurse-syllabus" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://testbook.com/uppsc-staff-nurse/previous-year-papers" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=7n2Ldy886mU" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://uppsc.up.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                   
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default UPPSC_Staff_Nurse