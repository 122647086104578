import style from "./000_Content.module.css"
import a1 from "./Pic/UPSC_IES_ISS_1.jpg"

const UPSC_IES_ISS=()=>{
    document.title="UPSC_IES_ISS Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>UPSC IES ISS Apply Online Form (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> Indian Economic Service / Indian Statistical Service Examination</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b> 10/04/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b> 30/04/2024 Till 06 PM</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b> Indian Economic Service (IES) | Indian Statistical Service Recruitment 2024 | Union Public Service Commission (UPSC). The UPSC IES / ISS 2024 Exam is open to those who would want to apply online between April 10 and April 30, 2024. To find out about the eligibility for the recruitment, the role, the selection process, the age restriction, the pay scale, and other details, read the announcement.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>UPSC IES ISS Apply Online Form (2024)</h1>
                    <h3>(Union Public Service Commission(UPSC))</h3>
                    <h2>Post Name – Indian Economic Service / Indian Statistical Service Examination</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 48</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b>10/04/2024</li></p>
                        <p><li><b>Last Date :-</b> 30/04/2024 Till 06 PM</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 30/04/2024</li></p>
                        <p><li><b>Correction Date :-</b> 01-07 May 2024</li></p> 
                        
                        <p><li><b>Exam Date :-</b> 21/06/2024</li></p>
                        <p><li><b>Admit Date :-</b>will be informed soon</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 200/-</li></p>
                        <p><li><b>SC/ST :-</b> No Fee</li></p> 
                        <p><li><b>PH(Disability) :-</b> No Fee</li></p> 
                        <p><li><b>All Female :-</b> No Fee</li></p> 
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit till 01/08/2024</h5></h1>  
                        <p><li><b>Minimum Age :-</b> 21 Years.</li></p> 
                        <p><li><b>Maximum Age :-</b>  30 Years.</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Indian Economic Service (IES)</div> 
                    <div className={style.q2}> 18</div> 
                    <div className={style.q3}> <li> A Master's degree in Economics, Applied Economics, Business Economics, or Econometrics, passed from any accredited Indian university.</li></div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Indian Statistical Service (ISS)</div> 
                    <div className={style.q2}> 30</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li> A master's degree in statistics, mathematical statistics, or applied statistics, or a passed/appearing bachelor's degree with statistics, mathematical statistics, or applied statistics as one of the subjects</li>
                    <p><b style={{color:"red"}}>NOTE:-</b>Only Unmarried Male Candidates are eligible for Applying NDA.</p></div>
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for UPSC IES/ISS Application Form (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- Indian Economic Service / Indian Statistical Service Examination.</h1>
                      <h2>Post Wise Vacancy Details 2024 :-</h2>
                      
                     <h3><li>Indian Economic Service (IES) :– 18 Posts</li></h3>
                     <h3><li>Indian Statistical Service (ISS) :– 30 Posts</li></h3>
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th&12th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>OTR Registration</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://upsc.gov.in/sites/default/files/Notifica-IES-ISS-Exam-2024-engl-100424.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://testbook.com/upsc-ies-iss/syllabus" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://upsc.gov.in/examinations/previous-question-papers?field_exam_name_value=indian+economic+service" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=UfdKN0cyuJk&ab_channel=GyanDevHost" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://upsc.gov.in/whats-new" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div ><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2 >@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default UPSC_IES_ISS