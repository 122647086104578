import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/Bank_Job.jpg"

const A001_Bank_job=()=>{
    document.title="Bank Jobs | Job For Sarkari"
    return(
        <div id={style.list}>
        <h1>Bank Jobs</h1>
        <h3>New Job issued on 15 December 2024</h3>
        <li><Link to="/sbiclerk1" style={{textDecoration:"none"}}><b className={style.a}>SBI (State Bank of India) Junior Associates Clerk Online Form 2024</b></Link></li>
       
        <h3>New Job issued on 27 October 2024</h3>
        <li><Link to="/idbibank" style={{textDecoration:"none"}}><b className={style.a}>IDBI Bank Executive Sales and Operations ESO Recruitment, Admit Card (2024)</b></Link></li>
        <li><Link to="/ibpspomt" style={{textDecoration:"none"}}><b className={style.a}>IBPS Probationary Officer / Management Trainee PO / MT Recruitment 2024, Mains Admit Card</b></Link></li>
        
        <h3>New Job issued on 1 July 2023</h3>
        <li><Link to="/ibpsclerkxii" style={{textDecoration:"none"}}><b className={style.a}> IBPS Clerk XIII Recruitment 2023</b></Link></li>
        <div><img src={img} alt="" style={{width:"100%", height:"400px"}} /></div>
        </div>
    )
}
export default A001_Bank_job