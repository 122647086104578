import style from "./000_Content.module.css"
import a1 from "./Pic/SSC_Chsl_1.jpg"

const SSC_Chsl=()=>{
    document.title="SSC CHSL 10+2 Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>SSC CHSL Recruitment Online Form 2024</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> LDC / JSA ,Postal Assistant / Sorting Assistant Or DEO Posts Recruitment</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>08/04/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>07/05/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>One of the India’s biggest recruitment board Staff Selection Commission  (SSC) has relased blended better Secondary level exam, 2024 SSC 10+2 CHSL Notification 2024. Those candidates who're interested by this SSC CHSL emptiness 2024 can practice on-line from Dates 08/04/2024 to 07/05/2024. Study the notification for recruitment eligibility, put up facts, choice technique, pay scale and all other records.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>SSC CHSL 10+2 Recruitment 2024</h1>
                    <h3>(Staff Selection Commission, Combined Higher Secondary Level)</h3>
                    <h2>Post Name – LDC / JSA ,Postal Assistant / Sorting Assistant Or DEO Posts Recruitment</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 3712</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 08/04/2024</li></p>
                        <p><li><b>Last Date :-</b> 07/05/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 08/05/2024</li></p>
                        <p><li><b>Correction Date :-</b>  10-11 May 2024</li></p>
                        <p><li><b>Exam Date Paper I :-</b>  June-July 2024</li></p>
                        <p><li><b>Exam Date Paper II :-</b> As per Schedule</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 100/-</li></p>            
                        <p><li><b>Sc/St :-</b> 0/-</li></p> 
                        <p><li><b>PH(Disability) :-</b> 0/-</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 01/08/2024</h5></h1>  
                          
                        <p><li><b>Minimum Age :-</b> 18 Years</li></p> 
                        <p><li><b>Maximum Age :-</b> 27 Years</li></p>   
                        <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> SSC CHSL 10+2</div> 
                    <div className={style.q2}> 3712</div> 
                    <div className={style.q3}> <li></li>Candidates who have passed their Class 10th, 12th Examination OR have Bachelor / Master Degree as per the eligibility of particular posts from recognized Board / University / Institute will be eligible for this recruitment.</div>
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for SSC CHSL 10+2 Recruitment (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- LDC / JSA, Postal Assistant / Sorting Assistant & DEO(2024)</h1>
                     <h2>Category Wise Vacancy Details 2023 :-</h2>
                     
                     <h3><li>General – Available Soon</li></h3>
                     <h3><li>EWS – Available Soon</li></h3>
                     <h3><li>OBC – Available Soon</li></h3>
                     <h3><li>SC – Available Soon</li></h3>
                     <h3><li>ST – Available Soon</li></h3>
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th,12th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://ssc.gov.in/candidate-portal/one-time-registration/home-page" target="_blank"><h2>Registration  | </h2></a>
                        <a href="https://ssc.gov.in/login" target="_blank"><h2>|  Log In</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://ssc.gov.in/api/attachment/uploads/masterData/NoticeBoards/Notice%20of%20CHSLE%202024_05_04_24.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/ssc-chsl-syllabus.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/ssc-chsl-previous-year-question-paper.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=8Jizzus3P5M&ab_channel=DSHELPINGFOREVER" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://ssc.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default SSC_Chsl