import style from "./000_Content.module.css"
import a1 from "./Pic/Bihar_RTPS_1.jpg"

const A0_Bihar_RTPS=()=>{
    document.title="Bihar जाति,आय, निवास प्रमाण पत्र Online Apply| Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Apply Online RTPS Bihar जाति,आय, निवास प्रमाण पत्र OR Check Status and Download Online</h1>
            
             <h2><b style={{color:"red"}}>Post-Name :-</b>Apply Online RTPS Bihar जाति,आय, निवास प्रमाण पत्र OR Check Status and Download Online</h2>
             <h2><b style={{color:"red"}}>State Name :- </b>Bihar</h2>
             <h2><b style={{color:"red"}}>During Process :- </b>2-5 Days</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>Friends, today we will tell you how to apply for RTPS Bihar Caste, Income, Resident Certificate online in a very simple way at home, and in this you will not need to give any OTP or go to the block, you will get your caste, income, resident certificate sitting at home. Will go, going to tell about it. So don't miss even a single step. For this, read this article till the end.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Apply RTPS Bihar जाति,आय, निवास प्रमाण पत्र Online</h1>
                    <h3>(Right to Public Service (RTPS) ACT)</h3>
                    <h2>Apply, Download or Check Status जाति,आय, निवास प्रमाण पत्र Online</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Www.JobForSarkari.Com</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Scheme Started Date :-</b> NA</li></p>
                        
                        <p><li><b>Last Date For Apply :-</b> NA</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>        
                          
                        <p><li>There are not any charges applicable for any application.</li></p>
                        
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>eligible for New Pan card</h1>
                        <p><li><b>Nationality :-</b>Be an Indian citizen</li></p>
                        <p><li><b>Place of Birth  :-</b>Be a permanent resident of Bihar</li></p>
                       
                    </div>
                    
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit</h5></h1>  
                        <p><li><b>Minimum Age :-</b>NA</li></p> 
                        <p><li><b>Maximum Age :-</b>NA</li></p> 
                                  
                    </div>
                </div>
                <div className={style.p4} > <div className={style.q1} style={{borderRight:"2px solid black"}}> <h5>Certificate  Name</h5></div> <div className={style.q3}> <h5>Some Information</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1} style={{borderRight:"2px solid black"}}> जाति प्रमाणपत्र</div> 
                    <div className={style.q3} > <li></li>भारत सरकार के द्वारा जाती प्रमाणपत्र देश के जो अनुसूचित जाती, अन्य पिछड़ा वर्ग और अनुसूचित जन जाति है उन लोगो के लिए जारी किया गया है। जाती प्रमाणपत्र के बिना लोगो को अनारक्षित/सामान्य श्रेणी माना जाता है।</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1} style={{borderRight:"2px solid black"}}> आय प्रमाण पत्र</div> 
                    <div className={style.q3} > <li></li>राज्य सरकार आय प्रमाणपत्र को जारी करता है। जो कि स्रोतों से किसी व्यक्ति की साल की आय को प्रमाणित करता है। आय प्रमापत्र बहुत ही ज्यादा महत्वपूर्ण है। क्युकी यह EWS Certificate के लिए आवश्यक है।</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1} style={{borderRight:"2px solid black"}}> निवास प्रमाण पत्र</div> 
                    <div className={style.q3} > <li></li>यह प्रमाणपत्र राज्य के लोगो के वहां के स्थायी निवासी होने का प्रमाणपत्र है। अगर आप सरकारी नौकरी के लिए जाते है तब आपसे यह प्रमाणपत्र मांगा जाता है।</div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Respective Documents Needed For Respective Modification :-</h1>
                    <h3><li>आधार कार्ड</li></h3>
                    <h3><li>पासपोर्ट फोटो</li></h3>
                    <h3><li>ईमेल id</li></h3>
                    <h3><li>मोबाइल नंबर</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Needed Mobile Or Laptop To Apply, Download जाति, आय, निवासी प्रमाण पत्र</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2> Apply Online जाति</h2></div>
                        <div><a href="https://serviceonline.bihar.gov.in/renderApplicationForm.do?serviceId=4650008&UUID=7a8cc254-dd5f-41c6-8e9c-153d9b15f923&directService=true&tempId=748&grievDefined=0&serviceLinkRequired=No&userLoggedIn=N&source=CTZN&OWASP_CSRFTOKEN=HD2X-TRRC-6X5G-T8PX-WP0M-YXA0-XI20-6TP2" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online आय</h2></div>
                        <div><a href="https://serviceonline.bihar.gov.in/renderApplicationForm.do?serviceId=4640005&UUID=100138cf-5696-4320-ab83-3c33c969f3a1&directService=true&tempId=747&grievDefined=0&serviceLinkRequired=No&userLoggedIn=N&source=CTZN&OWASP_CSRFTOKEN=2ESH-MHI4-XOKN-X9KB-CWH8-XVRO-T9TK-8W26" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online निवास</h2></div>
                        <div><a href="https://serviceonline.bihar.gov.in/serviceLinkHome.html?serviceToken=CgzhRQI7r54630001&janparichayEnabled=Y" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Application Status</h2></div>
                        <div><a href="https://serviceonline.bihar.gov.in/serviceLinkHome.html?serviceToken=CgzhRQI7r54630001&janparichayEnabled=Y" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Certificate</h2></div>
                        <div><a href="https://swcs.bihar.gov.in/RtpsReportView/certificate_download.jsp" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://serviceonline.bihar.gov.in/serviceLinkHome.html?serviceToken=CgzhRQI7r54630001&janparichayEnabled=Y" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default A0_Bihar_RTPS