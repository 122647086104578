import style from "./000_Content.module.css"
import a1 from "./Pic/PanCard1.jpg"

const A0_Pan_Card=()=>{
    document.title="Apply New Pan Card,Modification or Link to Aadhar Card Online | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Apply New Pan Card, Download Pan Card, Modification of Pan Card, Link to Aadhar Card Online</h1>
             
             <h2><b style={{color:"red"}}>Post-Name :-</b>Apply New Pan Card,Modification or Link to Aadhar Card Online</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>NA</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>NA</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>UTITSL is invited to apply online for a Permanent Account Number (PAN) card. Indian citizens who would want to create a new PAN card, modify an existing one, or get updates can read the complete details and apply online.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>New Pan Card,Modification or Link to Aadhar Card Online</h1>
                    <h3>(Permanent Account Number (PAN Card))</h3>
                    <h2>Apply New Pan Card, Download,Modify or link to Aadhar Card Online</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Www.JobForSarkari.Com</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Scheme Started Date :-</b> July 1, 1975</li></p>
                        
                        <p><li><b>Last Date For Apply :-</b> NA</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>        
                        <p><li><b>New Pan Card :-</b> 101/-</li></p>   
                        <p><li><b>New Pan Card(NRI) :-</b> 1011/-</li></p>  
                        <p><li><b>Correction Of Pan Card  :- </b> 50/-</li></p>  
                        <p><li><b>Correction Of Pan Card(NRI)  :- </b> 959/-</li></p>  
                        <p><li>There are no charges applicable for instant e-PAN cards applied on the Income Tax e-filing portal.</li></p>
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> You can pay their Charges / Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>eligible for New Pan card</h1>
                        <p><li><b>Nationality :-</b>Indian</li></p>
                        <p><li><b>Place of Birth  :-</b>India</li></p>
                        <p><li>NRIs who have a source of income in India or are involved in any financial transactions in India can apply for a PAN card.</li></p>
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit</h5></h1>  
                        <p><li><b>Minimum Age :-</b>NA</li></p> 
                        <p><li><b>Maximum Age :-</b>NA</li></p> 
                                  
                    </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Respective Documents Needed For Respective Modification :-</h1>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Needed Mobile Or Laptop To Download, Modify Aadhar Card</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2> Apply Instant Pan Card</h2></div>
                        <div><a href="https://eportal.incometax.gov.in/iec/foservices/#/pre-login/instant-e-pan" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply New Pan Card </h2></div>
                        <div><a href="https://www.pan.utiitsl.com/PAN/mainform.html;jsessionid=E882D3DCCE0310D7A73B47796F478F15" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Link Pan Card to Aadhar</h2></div>
                        <div><a href="https://www.incometaxindiaefiling.gov.in/e-Filing/Services/LinkAadhaarHome.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Correction/Status of Pan Card</h2></div>
                        <div><a href="https://www.pan.utiitsl.com/PAN/mainform.html;jsessionid=E882D3DCCE0310D7A73B47796F478F15" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.pan.utiitsl.com/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default A0_Pan_Card