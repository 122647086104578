import style from "./000_Content.module.css"
import a1 from "./Pic/Rajasthan_TGT_Teacher_1.jpg"

const Rajasthan_TGT_Teacher_1=()=>{
    document.title="Rajasthan TGT Teacher Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Rajasthan RPSC Senior Teacher Grade II TGT Teacher Recruitment Online Form(2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>  Senior Teacher Grade II (TGT) Recruitment (2024)</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>26/12/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>24/01/2025</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The Rajasthan Public Service Commission (RPSC) conducts the Senior Teacher Grade II (TGT) Recruitment to fill teaching positions in government schools across Rajasthan. This recruitment is for candidates aspiring to teach specific subjects at the middle and secondary school levels (classes 6-10). Senior Teacher (TGT) for subjects like Hindi, English, Mathematics, Science, Social Science, Sanskrit, and others. Candidates must have a graduate degree in the relevant subject along with a B.Ed. or equivalent teaching qualification.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>RPSC Senior Teacher Grade II TGT Teacher Recruitment (2024)</h1>
                    <h3>(Rajasthan Public Service Commission (RPSC) )</h3>
                    <h2>Post Name –  Senior Teacher Grade II (TGT) Recruitment</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 2129</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 26/12/2024</li></p>
                        <p><li><b>Last Date :-</b> 24/01/2025</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 24/01/2025</li></p>
                        <p><li><b>Exam Date :-</b> To be announced</li></p> 
                        <p><li><b>Admit Card :-</b> To be announced</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / Other State :-</b> 600/-</li></p>
                        <p><li><b>OBC / BC :-</b> 400/-</li></p> 
                        <p><li><b>SC/ST :-</b> 400/-</li></p> 
                        <p><li><b>Correction Charge :-</b> 500/-</li></p> 
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Rajasthan</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 01/01/2025</h5></h1>  
                         
                        <p><li><b>Minimum Age :-</b>18 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>40 Years </li></p>   
                        <p><li>Additional age relaxation as per RPSC Senior Teacher II recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> Hindi, English, Mathematics, Sanskrit, Urdu, Punjabi</div> 
                    <div className={style.q2}> 1691</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Graduate or equivalent examination recognized by UGC with concerned subject as Optional Subject.</li><li>Degree or Diploma in Education recognized by NCTE.</li></div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Science</div> 
                    <div className={style.q2}> 350</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Graduate or equivalent examination recognized by UGC with at least 2 of the following subjects as Optional Subject (Physics, Chemistry, Zoology, Botany, Bio-Technology, Biochemistry).</li><li>Degree or Diploma in Education recognized by NCTE.</li></div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Social Science</div> 
                    <div className={style.q2}> 88</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Graduate or equivalent examination recognized by UGC with at least 2 of the following subjects as Optional Subject- History, Geography, Economics, Political Science, Sociology, Public Administration, Philosophy.</li><li>Degree or Diploma in Education recognized by NCTE.</li> <li>For More Eligibility Details, Please Read the Official Notification.</li></div>
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for Rajasthan RPSC Senior Teacher Grade II Recruitment (2024) </h1>
                     
                     <div>
                     <h1>Post Name :-  Senior Teacher Grade II (TGT) Recruitment (2024)</h1>
                      <h2>Category Wise Vacancy Details 2024 :-</h2>
                     <h3><li> Non TSP Area :– 1727 Posts</li></h3>
                     <h3><li> TSP Area :– 402 Posts</li></h3>
                     <h2>Subject Wise Vacancy Details 2024 :-</h2>
                     <h3><li> Hindi :– 288 Posts</li></h3>
                     <h3><li> English :– 327 Posts</li></h3>
                     <h3><li> Mathematics :– 694 Posts</li></h3>
                     <h3><li> Science :– 350 Posts</li></h3>
                     <h3><li> Social Science :– 88 Posts</li></h3>
                     <h3><li> Sanskrit :– 309 Posts</li></h3>
                     <h3><li> Punjabi :– 64 Posts</li></h3>
                     <h3><li> Urdu :– 09 Posts</li></h3>
                     </div>
                </div>
                <div id={style.p55}>
    <h1>How To Apply Rajasthan RPSC Senior Teacher Grade II TGT Teacher Recruitment 2024</h1>
    <div>
        <h1>Here, We describe Step By Step how to fill the RPSC Senior Teacher Grade II TGT Form Online :-</h1>
        
        <h2>Step 1{`)`} :- Check Eligibility</h2>
        <h3>
            <ol>
                <li>Age Limit: Typically 18-40 years (age relaxation for reserved categories as per government norms).</li>
                <li>Educational Qualification :- </li>
                    <ul>
                        <li>Graduation in the relevant subject from a recognized university.</li>
                        <li>B.Ed. degree or equivalent teaching qualification.</li>
                    </ul>
               
            </ol>
        </h3>

        <h2>Step 2{`)`} :- Visit the Official Website</h2>
        <h3>
            <ol>
                <li>Go to the official RPSC website: <a href="https://rpsc.rajasthan.gov.in/">https://rpsc.rajasthan.gov.in/</a>.</li>
            </ol>
        </h3>

        <h2>Step 3{`)`} :- Register as a New User (if not already registered)</h2>
        <h3>
            <ol>
                <li>On the homepage, click on "New Registration" under the Login section.</li>
                <li>Fill in the required details for One-Time Registration :-</li>
                    <ul>
                        <li>Basic Details (Name, Father's Name, Date of Birth, etc.)</li>
                        <li>Contact Information (Email ID and Mobile Number)</li>
                        <li>Permanent Address</li>
                    </ul>
                
                <li>Upload your scanned photograph and signature as per the specified dimensions :- </li>
                    <ul>
                        <li>Photograph: 50-100 KB (JPEG format).</li>
                        <li>Signature: 20-50 KB (JPEG format).</li>
                    </ul>
                
                <li>Submit the details and note down the Registration ID and Password sent to your registered email/mobile.</li>
            </ol>
        </h3>

        <h2>Step 4{`)`} :- Log In to Your Account</h2>
        <h3>
            <ol>
                <li>Use your Registration ID and Password to log in to the RPSC portal.</li>
                <li>Change your password if prompted.</li>
            </ol>
        </h3>

        <h2>Step 5{`)`} :- Apply for RPSC Senior Teacher Grade II Recruitment</h2>
        <h3>
            <ol>
                <li>After logging in, go to the “Apply Online” section.</li>
                <li>Select the link for Rajasthan Senior Teacher Grade II Recruitment.</li>
                <li>Fill in the required details in the application form :-  </li>
                    <ul>
                        <li>Examination Center Preference</li>
                        <li>Educational Qualification</li>
                        <li>Category (General, OBC, SC/ST, etc.)</li>
                        <li>Other personal details</li>
                    </ul>
                
                <li>Verify all details carefully before submitting.</li>
            </ol>
        </h3>

        <h2>Step 6{`)`} :- Upload Documents</h2>
        <h3>
            <ol>
                <li>Upload your scanned photograph and signature (if not done during registration).</li>
                <li>Follow the dimensions and file size guidelines mentioned on the form.</li>
            </ol>
        </h3>

        <h2>Step 7{`)`} :- Pay the Application Fee</h2>
        <h3>
            <ol>
                <li>Application fee varies by category :-  </li>
                    <ul>
                        <li>General/Other State :- 600/-</li>
                        <li>OBC / BC :- 400/-</li>
                        <li>SC/ST :- 400/-</li>
                    </ul>
                
                <li>Payment can be made via :- </li>
                    <ul>
                        <li>Online: Credit/Debit Card, Net Banking, UPI.</li>
                        <li>Offline: Generate a Challan and pay at the designated bank.</li>
                    </ul>
                
            </ol>
        </h3>

        <h2>Step 8{`)`} :- Submit the Application</h2>
        <h3>
            <ol>
                <li>After completing all steps, click on the “Final Submit” button.</li>
                <li>Take a printout of the application form for future reference.</li>
            </ol>
        </h3>
    </div>
</div>

                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://sso.rajasthan.gov.in/signin" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://doc.sarkariresults.org.in/RPSC_TGT_Teacher_SarkariResult_Notification.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.adda247.com/teaching-jobs-exam/rpsc-grade-ii-teacher-syllabus/?srsltid=AfmBOopRpkt1itS8WZgTzayiGM6rUO0IKAwclmbMXG7ufHxswQc8zT0p" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.adda247.com/teaching-jobs-exam/rpsc-grade-2-previous-year-paper/?srsltid=AfmBOoqF_xZZNfMsghG0IsHPBHM4qoErSkFOLco9yF08vLPK6EQZUMwr" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=foDIasPCyeg" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://ssc.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default Rajasthan_TGT_Teacher_1