import style from "./000_Content.module.css"
import a1 from "./Pic/Clat_1.jpg"

const CLAT_UG_PG_Entrance=()=>{
    document.title="CLAT UG/PG Entrance Admit Card | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>CLAT (Common Law Admission Test) UG / PG Entrance Exam, Admit Card 2024</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>CLAT(Common Law Admission Test) UG / PG Entrance Exam 2025</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>15/07/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>15/10/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The Consortium of National Law Universities has announced that the online application process for admission to 2025 will open on July 15, 2024, and close on October 15, 2024. Numerous universities are taking part in CLAT 2025; before applying, applicants should read the information booklet to learn about all the details of the entrance test, including the curriculum, eligibility, age restriction, placement, fees, college information, and other details.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>CLAT UG / PG Entrance Exam (2025)</h1>
                    <h3>(Common Law Admission Test (CLAT))</h3>
                    <h2>Post Name – CLAT UG / PG Entrance Exam 2025</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - Eligibility Test</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 15/07/2024</li></p>
                        <p><li><b>Last Date :-</b> 15/10/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 15/10/2024</li></p>
                        <p><li><b>Admit Card :-</b>20/11/2024</li></p> 
                        <p><li><b>Exam Date :-</b>Notified Soon</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1> 
                        <p><li><b>General / OBC  :-</b> 4000/-</li></p>
                        <p><li><b>SC/ST/PH :-</b> 3500</li></p> 
                       <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Eligibility Test</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Minimum Age  :-</b>No Restriction</li></p> 
                        <p><li><b>Maximum Age:-</b>No Restriction</li></p>
                        </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> CLAT UG / PG Entrance Exam</div> 
                    <div className={style.q2}> NA</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li> Under Graduate B.A / LL.B. Hons 5 Yr : Passed 10+2 (Intermediate)Exam in Any Recognized Board With 45% Marks</li>  <li>For Reserve Category: 40% Marks</li> <li>Passed / Appearing Both Candidates Are Eligible</li></div>
                </div>
               
                <div id={style.p5}>
                    <h1>Details About CLAT UG / PG Entrance Exam (2025) </h1>
                     
                     <div>
                     <h1>Post Name :- CLAT UG / PG Entrance Exam 2025.</h1>
                      <h2>Participating National Law Universities Notification:-</h2>
                      
                     <h3><li>{`1.)`} NLSIU Bengaluru</li></h3>
                     <h3><li>{`2.)`} NALSAR Hyderabad</li></h3>    
                     <h3><li>{`3.)`} NLIU Bhopal</li></h3>
                     <h3><li>{`4.)`} WBNUJS Kolkata</li></h3> 
                     <h3><li>{`5.)`} NLU Jodhpur</li></h3>
                     <h3><li>{`6.)`} HNLU Raipur</li></h3> 
                     <h3><li>{`7.)`} GNLU Gandhinagar</li></h3>
                     <h3><li>{`8.)`} GNLU, Silvassa Campus Silvassa</li></h3> 
                     <h3><li>{`9.)`} RMLNLU Lucknow</li></h3>
                     <h3><li>{`10.)`} RGNUL Punjab</li></h3> 
                     <h3><li>{`11.)`} CNLU Patna</li></h3>
                     <h3><li>{`12.)`} NUALS Kochi</li></h3> 
                     <h3><li>{`13.)`} NLUO Odisha</li></h3>
                     <h3><li>{`14.)`} NUSRL Ranchi</li></h3> 
                     <h3><li>{`15.)`} NLUJA Assam</li></h3>
                     <h3><li>{`16.)`} DSNLU Visakhapatanam</li></h3> 
                     <h3><li>{`17.)`} TNNLU Tiruchirappalli</li></h3>
                     <h3><li>{`18.)`} MNLU Mumbai</li></h3> 
                     <h3><li>{`19.)`} MNLU Nagpur</li></h3>
                     <h3><li>{`20.)`} MNLU Aurangabad</li></h3> 
                     <h3><li>{`21.)`} HPNLU Shimla</li></h3>
                     <h3><li>{`22.)`} DNLU Jabalpur</li></h3> 
                     <h3><li>{`23.)`} DBRANLU Haryana</li></h3>
                     <h3><li>{`24.)`} NLUT AGARTALA</li></h3> 
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Respective ID Proof</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Admit Card</h2></div>
                        <div><a href="https://consortiumofnlus.ac.in/clat-2025/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://consortiumofnlus.ac.in/clat-2025/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://consortiumofnlus.ac.in/images/CLAT-2025-Notification.png" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://consortiumofnlus.ac.in/clat-2025/ug-syllabus.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/school/question-paper/clat-previous-year-question-paper" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=i9fgHdyGzH4" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://consortiumofnlus.ac.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default CLAT_UG_PG_Entrance