import style from "./000_Content.module.css"
import a1 from "./Pic/IRDAI_Associate_Manager_1.jpg"

const IRDAI_Assistant_Manager=()=>{
    document.title="IRDAI Assistant Manager Phase I Result | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1> IRDAI (Insurance Regulatory and Development Authority of India) Assistant Recruitment, Phase I Result Out (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> Insurance Regulatory and Development Authority of India IRDAI Assistant Online Form 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>21/08/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>20/09/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b> Assistant Manager Recruitment at the Insurance Regulatory and Development Authority of India (IRDAI) 2024. The results of the IRDAI Assistant Manager Examination are available for download by those who are interested. To learn about the recruitment eligibility, position details, selection process, age restriction, pay scale, and other details, read the IRDAI Assistant Manager 49 position notice.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1> IRDAI Assistant Recruitment online Form (2024)</h1>
                    <h3>(Insurance Regulatory and Development Authority of India(IRDAI))</h3>
                    <h2>Post Name – Insurance Regulatory and Development Authority of India Assistant Recruitment (2024)</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 49</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 21/08/2024</li></p>
                        <p><li><b>Last Date :-</b> 20/09/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 20/09/2024</li></p>
                        <p><li><b> Phase I Admit Card :-</b> 24/10/2024</li></p> 
                        <p><li><b>Phase I CBT Exam Date :-</b> 06/11/2024</li></p>
                        <p><li><b>Phase I Result Date :-</b> 26/11/2024</li></p> 
                        <p><li><b>Phase II Exam Date :-</b> 21/12/2024</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 750/-</li></p>
                        <p><li><b>SC/ST :-</b> 100/-</li></p> 
                        <p><li><b>PH(Disability) :-</b> 175/-</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Minimum Age :-</b>21 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>30 Years</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> General</div> 
                    <div className={style.q2}> 24</div> 
                    <div className={style.q3}> <li></li>Bachelor Degree in Any Stream with Minimum 60% Marks.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}>Law</div> 
                    <div className={style.q2}> 05</div> 
                    <div className={style.q3}> <li></li>Bachelor Degree in Law (LLB) with Minimum 60% Marks.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Actuarial</div> 
                    <div className={style.q2}> 05</div> 
                    <div className={style.q3}> <li></li>Bachelor Degree in Any Stream with Minimum 60% Marks and 7 papers passed of IAI as per 2019 curriculum.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Finance</div> 
                    <div className={style.q2}> 05</div> 
                    <div className={style.q3}> <li></li>Bachelor Degree with Minimum 60% Marks ACA/ AICWA/ ACMA/ ACS/ CFA Degree.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Information Technology IT</div> 
                    <div className={style.q2}> 05</div> 
                    <div className={style.q3}> <li></li>Bachelor Degree in Any Stream with PG Degree in Computer / IT with 60% Marks OR MCA OR BE / B.Tech Degree in Electrical / Electronics / Electronics and Communication / Information Technology / Computer Science/ Software Engineering) with minimum 60% marks.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Research</div> 
                    <div className={style.q2}> 05</div> 
                    <div className={style.q3}> <li></li>Master Degree OR 2 Year Diploma in Economics / Statistics with Minimum 60% Marks.
                    </div>
                </div>
                <div id={style.p5}>
                    <h1>Vacancy Details for IRDAI Assistant Recruitment Online Form(2024) </h1>
                     
                     <div>
                     <h1>Post Name :- Insurance Regulatory and Development Authority of India Assistant Recruitment (2024).</h1>
                      <h2>Category Wise Vacancy Details 2024 :-</h2>
                      
                     <h3><li>UR :– 21  Posts</li></h3>
                     <h3><li>EWS :– 04 Posts</li></h3>
                     <h3><li>OBC :– 12 Posts</li></h3>
                     <h3><li>SC :– 08 Posts</li></h3>
                     <h3><li>ST :– 04 Posts</li></h3>
                     <h3><li>Total:– 49 Posts</li></h3> 
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Left Thumb Impression</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}> 
                <div>
                        <div><h2>Pre Score Card</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crppo14jul24/opes_nov24/login.php?appid=4b69f94d2baa42f75cef4b6af5f3a517" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Admit Card (Mains)</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crppo14jul24/oecla_nov24/login.php?appid=7fbb66d8ef02416a0b6925467b2baa8b" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Pre Result</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crppo14jul24/res1a_nov24/login.php?appid=dbad66c45d0a300d72fa618cbb4443c8" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Pre Admit Card</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crppo14jul24/oecla_oct24/downloadClose.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crppo14jul24/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://doc.sarkariresults.org.in/upload/Detailed-Notification_CRP-PO-XIV_final-1.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/ibps-po-syllabus-exam-pattern.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/ibps-po-previous-year-question-paper.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=pF7DvMRwpas" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.ibps.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default IRDAI_Assistant_Manager