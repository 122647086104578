import style from "./000_Content.module.css"
import a1 from "./Pic/UPSC_CDS_1.jpg"

const UPSC_CDS=()=>{
    document.title="UPSC CDS I Admit Card | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>UPSC CDS I Admit Card (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> Combined Defence Service (CDS) I Exam 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>20/12/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>09/01/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>Union Public Service Commission (UPSC) conducts Combined Defence Service Exam every year & lakhs of candidates participate for the aforesaid examination. Same as for the year 2024 also, UPSC has release the UPSC Combined Defence Service Exam 2023 CDS II And the Online Process for UPSC Combined Defence Service Exam 2023 CDS II has started from 17 May 2023. Now, the candidates who wish to apply for the UPSC Combined Defence Service Exam CDS II Recruitment 2023 can proceed forward for filling up the online application form.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>UPSC CDS II Online Form (2024)</h1>
                    <h3>(Union Public Service Commission(UPSC))</h3>
                    <h2>Post Name – Combined Defence Service (CDS) I Exam (2024)</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 457</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 20/12/2023</li></p>
                        <p><li><b>Last Date :-</b> 09/01/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 09/01/2024</li></p>
                        <p><li><b>Correction Date :-</b> 10-16 January 2024</li></p> 
                        <p><li><b>Admit Date :-</b> 12/04/2024</li></p>
                        <p><li><b>Exam Date :-</b> 21/04/2024</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 200/-</li></p>
                        <p><li><b>SC/ST :-</b> No Fee</li></p> 
                        <p><li><b>PH(Disability) :-</b> No Fee</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Candidate Born After :-</b>02/01/2001</li></p> 
                        <p><li><b>Candidate Born Before :-</b>01/01/2006</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Indian Military Academy</div> 
                    <div className={style.q2}> 100</div> 
                    <div className={style.q3}> <li></li>Candidates having graduation Degree in any stream from any recognized University / Institution will be considered for this post.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Air force Academy</div> 
                    <div className={style.q2}> 32</div> 
                    <div className={style.q3}> <li></li>Candidates having Graduation Degree in Physics & Maths at 10+ 2 Level/Bachelor of Engineering from a recognized University / Institution will be considered for this post.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Indian Naval Academy</div> 
                    <div className={style.q2}> 22</div> 
                    <div className={style.q3}> <li></li>Candidates having Engineering Degree in relevant trade will be considered for this post.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Officer Training Academy OTA </div> 
                    <div className={style.q2}> 170 (male) / 10 (Women)</div> 
                    <div className={style.q3}> <li></li>Candidates having Bachelor Degree in any Stream from a recognized University / Institution will be considered for this post.</div>
                    
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for UPSC CDS II Application Form (2023) </h1>
                     
                     <div>
                     <h1>Post Name :- Combined Defence Service (CDS) I Exam (2023).</h1>
                      <h2>Physical Details for UPSC CDS I 2023 :-</h2>
                      <h3><b>Height :-</b> 162.5cms</h3>
                    <h3><b>Leg length :-</b> 99 cms (Minimum), 120 cms(Maximum)</h3>
                    <h3><b>Thigh Length :-</b> 64 Cms (Maximum)</h3>
                    <h3><b>Sitting height :-</b> 81.5 Cms (Minimum), 96 cms(Maximum)</h3>
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th&12th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Download Admit Card</h2></div>
                        <div><a href="https://upsconline.nic.in/eadmitcard/admitcard_cdsi_2024/admit_card.php#hhh1" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>OTR Registration</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://upsc.gov.in/sites/default/files/Notif-CDS-I-2024-Eng-20122023.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.studyiq.com/articles/cds-syllabus/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.upsc.gov.in/examinations/previous-question-papers?field_exam_name_value=Combined+Defence+Services" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=d2pNnzTzm_k&ab_channel=GyanDevHost" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://upsc.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default UPSC_CDS