import style from "./000_Content.module.css"
import s1 from "./Pic/SBI_Clerk_2.jpg"

const SBI_Clerk_1=()=>{
    document.title="SBI Clerk Apply Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>SBI(State Bank of India) Junior Associates Customer Support and Sales Recruitment (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>SBI Junior Associates Customer Support and Sales Recruitment</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>14/12/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>02/01/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The SBI Clerk is a position in the State Bank of India, one of the largest public sector banks in India. As a clerk, the primary responsibilities include handling customer interactions, processing transactions, account management, and assisting in various banking operations. This role is often referred to as a Junior Associate (Customer Support & Sales).It is a popular banking exam for candidates aspiring to start a career in the banking sector.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>SBI Junior Associate Clerk Recruitment (2024)</h1>
                    <h3>(State Bank of India (SBI))</h3>
                    <h2>Post Name – SBI Junior Associate Customer Support and Sales 2024</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 13,735</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 17/12/2024</li></p>
                        <p><li><b>Last Date :-</b> 07/01/2025</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 07/01/2025</li></p>
                        <p><li><b>Phase I Exam Date :-</b>February 2025</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1> 
                        <p><li><b>General / OBC / EWS :-</b> 750/-</li></p>
                        <p><li><b>SC/ST/PH :-</b> 0/-</li></p> 
                        
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit till 01/04/2024</h5></h1>  
                        <p><li><b>Minimum Age  :-</b>20 Years</li></p> 
                        <p><li><b>Maximum Age:-</b>28 Years</li></p>
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Junior Associates (Clerk)</div> 
                    <div className={style.q2}> 13735</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Passed / Appearing Bachelor Degree in Any Stream in Any Recognized University in India.</li> 
                    <li>Knowledge of Local Language</li>
                    <li>For More Eligibility Details, Please Read the Official Notification.</li></div>
                </div>
                 
                <div id={style.p5}>
                    <h1>Vacancy Details of SBI Clerk Recruitment (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- SBI Junior Associates Customer Support and Sales Recruitment</h1>
                     <h2>Category Wise Vacancy Details :-</h2>
                     <h3><li>UR :– 5870  Posts</li></h3>
                     <h3><li>EWS :– 1361 Posts</li></h3>
                     <h3><li>OBC :– 3001 Posts</li></h3>
                     <h3><li>SC :– 2118 Posts</li></h3>
                     <h3><li>ST :– 1385 Posts</li></h3>
                     <h3><li>Total:– 13735 Posts</li></h3>  
                      <h2>State Wise Vacancy Details :-</h2>
                      <h3><li>Andaman & Nicobar :– 70 Posts </li></h3>
                     <h3><li>Andhra Pradesh :– 50 Posts</li></h3>
                     <h3><li>Arunachal Pradesh :– 66 Posts</li></h3>
                     <h3><li>Assam :– 311 Posts</li></h3>
                     <h3><li>Bihar :– 1111 Posts</li></h3>
                     <h3><li>Chandigarh :– 32 Posts</li></h3>
                     <h3><li>Chhattisgarh :– 483 Posts</li></h3>
                     {/* <h3><li>Dadar Nagar / Daman Diu :– 08 Posts</li></h3> */}
                     <h3><li>Delhi :– 343 Posts</li></h3>
                     <h3><li>Goa  :– 20 Posts</li></h3>
                     <h3><li>Gujarat  :– 1073 Posts</li></h3>
                     <h3><li>Haryana :– 306 Posts</li></h3>
                     <h3><li>Himachal Pradesh :– 170 Posts</li></h3>
                     <h3><li>Jammu & Kashmir :– 141 Posts</li></h3>
                     <h3><li>Jharkhand :– 676 Posts</li></h3>
                     <h3><li>Kerala :– 426 Posts</li></h3>
                     <h3><li>Karnataka :– 50 Posts</li></h3>
                     <h3><li>Lakshadeep :– 2 Posts</li></h3>
                     <h3><li>Madhya Pradesh :– 1317 Posts</li></h3>
                     <h3><li>Maharashtra :– 1163 Posts</li></h3>
                     <h3><li>Manipur :– 55 Posts</li></h3>
                     <h3><li>Meghalaya :– 85 Posts</li></h3>
                     <h3><li>Mizoram :– 40 Posts</li></h3>
                     <h3><li>Nagaland :– 70 Posts</li></h3>
                     <h3><li>Odisha :– 362 Posts</li></h3>
                     <h3><li>Puducherry :– 4 Posts</li></h3>
                     <h3><li>Punjab :– 569 Posts</li></h3>
                     <h3><li>Rajasthan :– 445 Posts</li></h3>
                     <h3><li>Sikkim :– 56 Posts</li></h3>
                     <h3><li>Tamil Naidu :– 336 Posts</li></h3>
                     <h3><li>Telangana :– 342 Posts</li></h3>
                     <h3><li>Tripura :– 65 Posts</li></h3>
                     <h3><li>Uttarakhand :– 316 Posts</li></h3>
                     <h3><li>Uttar Pradesh :– 1894 Posts</li></h3>
                     <h3><li>West Bengal  :– 1254 Posts</li></h3>        
                     </div>
                </div>
                <div id={style.p55}>
    <h1>How To Apply SBI Clerk Online Form</h1>
    <div>
        <h1>Here, We describe Step By Step how to fill the SBI Clerk Form Online:</h1>
        <h2>Step 1{`)`} :- Check Eligibility</h2>
        <h3>
            <ol>
                <li>Age Limit: Typically 20-28 years (age relaxation for reserved categories as per government norms).</li>
                <li>Educational Qualification: A Graduate degree in any discipline from a recognized university.</li>
            </ol>
        </h3>
        <h2>Step 2{`)`} :- Visit the Official Website</h2>
        <h3>
            <ol>
                <li>Go to the official SBI website: <a href="https://sbi.co.in/">https://sbi.co.in/</a>.</li>
            </ol>
        </h3>
        <h2>Step 3{`)`} :- Register as a New User (if not already registered)</h2>
        <h3>
            <ol>
                <li>On the home page, navigate to the Careers section and click on the “Join SBI” link.</li>
                <li>Select "Current Openings" and choose "Junior Associate (Customer Support & Sales)".</li>
                <li>Click on "Apply Now" and complete the registration process by providing the required details:</li>
                <ul>
                    <li>Basic Details (Name, Date of Birth, Contact Details, etc.)</li>
                    <li>Contact Information (Email ID and Mobile Number)</li>
                </ul>
                <li>Upload your scanned photograph and signature as per the specified dimensions:</li>
                <ul>
                    <li>Photograph: 20-50 KB (JPEG format).</li>
                    <li>Signature: 10-20 KB (JPEG format).</li>
                </ul>
                <li>Submit the details and note down the Registration Number and Password sent to your registered email/mobile.</li>
            </ol>
        </h3>
        <h2>Step 4{`)`} :- Log In to Your Account</h2>
        <h3>
            <ol>
                <li>Use your Registration Number and Password to log in to the SBI portal.</li>
                <li>Change your password if prompted.</li>
            </ol>
        </h3>
        <h2>Step 5{`)`} :- Fill the Application Form</h2>
        <h3>
            <ol>
                <li>After logging in, go to the “Apply Online” section for the SBI Clerk exam.</li>
                <li>Fill in the application form with the following details:</li>
                <ul>
                    <li>Personal Information</li>
                    <li>Educational Qualification</li>
                    <li>Preferred Exam Centers</li>
                    <li>Category (General, OBC, SC/ST, etc.)</li>
                </ul>
                <li>Verify all details carefully before submitting the form.</li>
            </ol>
        </h3>
        <h2>Step 6{`)`} :- Upload Documents</h2>
        <h3>
            <ol>
                <li>Upload your scanned photograph and signature (if not done during registration).</li>
                <li>Ensure the dimensions and file size guidelines are followed.</li>
            </ol>
        </h3>
        <h2>Step 7{`)`} :- Pay the Application Fee</h2>
        <h3>
            <ol>
                <li>The fee for General/OBC/EWS candidates is typically ₹750, while SC/ST/PwD candidates are exempt.</li>
                <li>Payment can be made via:</li>
                <ul>
                    <li>Online: Credit/Debit Card, Net Banking, UPI.</li>
                </ul>
            </ol>
        </h3>
        <h2>Step 8{`)`} :- Submit the Application</h2>
        <h3>
            <ol>
                <li>After completing all steps, click on the “Final Submit” button.</li>
                <li>Take a printout of the application form and the fee receipt for future reference.</li>
            </ol>
        </h3>
    </div>
</div>

                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Scanned Photograph and Signature</li></h3>
                    <h3><li>ID Proof</li></h3>
                    <h3><li>10th/Matriculation Certificate</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>PAN Card</li></h3>
                    <h3><li>Educational Qualification Certificates</li></h3>
                    <h3><li>Caste Certificate (if applicable)</li></h3>
                    <h3><li>Disability Certificate (if applicable)</li></h3>
                    <h3><li>Income and Asset Certificate (for EWS candidates)</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/sbidrjadec24/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://doc.sarkariresults.org.in/SBI%20Clerk%202024.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/sbi-clerk-syllabus.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/sbi-clerk-previous-year-question-paper.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=meFUroihAF4" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://bank.sbi/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={s1} alt="" id={style.pic}/>
        </div>
        
        <div id={style.ins}>
        
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default SBI_Clerk_1