import style from "./000_Content.module.css"
import a1 from "./Pic/BPSC_2.jpg"


const BPSC_2=()=>{
    document.title="Bihar BPSC 69th Final Result | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Bihar BPSC (Bihar Public Service Commission) 69th Pre Exam Recruitment 2023, Final Result (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>Bihar BPSC 69th Pre Exam Recruitment Online Form 2023</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>15/07/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>05/08/2023</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The announcement for the 346 69th Combined Competitive Examination and other exams in 2023 has been made public by the Bihar Public Service Commission (BPSC). The Final Result 2024 may be downloaded by those candidates who are enrolled with a vacancy. For details on the BPSC Pre 69th Recruitment 2023 age restriction, curriculum, department-specific posts, selection process, and pay package, view the advertisement.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Bihar BPSC 69th Pre Exam Online Form (2024)</h1>
                    <h3>(Bihar Public Service Commission (BPSC))</h3>
                    <h2>Post Name – Bihar BPSC 69th Pre Exam Recruitment 2023</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 349</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b>15/07/2023</li></p>
                        <p><li><b>Last Date :-</b> 05/08/2023</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 09/08/2023</li></p>
                        <p><li><b>Pre Exam Date :-</b> 30/09/2023</li></p> 
                        <p><li><b>Admit Card :-</b>15/09/2023</li></p>
                        <p><li><b>Venue Details :-</b> 26/09/2023</li></p>
                        <p><li><b>Question Paper Available :-</b> 01/10/2023</li></p>
                        <p><li><b>Answer Key Date :-</b>06/10/2023</li></p>
                        <p><li><b>Mains Online Form :-</b> 27/11/2023 to 06/12/2023</li></p>
                        <p><li><b>Mains Exam Start Date :-</b> 03/01/2024</li></p>
                        <p><li><b>Mains Admit Card:-</b> 28/12/2023</li></p> 
                        <p><li><b>Mains Result Date :-</b>31/08/2024</li></p>
                        <p><li><b>Interview Date :-</b> 15/10/2024</li></p>
                        <p><li><b>Final Result Date :-</b> 26/11/2024</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 600/-</li></p>
                        <p><li><b>SC/ST :-</b> 150/-</li></p> 
                        <p><li><b>PH(Disability) :-</b> 150/-</li></p> 
                        <p><li><b>Female(Bihar Domicile) :-</b> 150/-</li></p> 
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Bihar</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit till 01/08/2023</h5></h1>  
                        <p><li><b>Minimum Age :-</b> 20 - 22 Years.</li></p> 
                        <p><li><b>Maximum Age Male:-</b>  37 Years.</li></p> 
                        <p><li><b>Maximum Age Female:-</b>  40 Years.</li></p> 
                       <p><li>Additional age relaxation as per BPSC recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Bihar BPSC 69th</div> 
                    <div className={style.q2}> 349</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Bachelor Degree in Any Stream in Any Recognized University in India</li>  <li> For Post Wise Eligibility Details Read the Notification</li></div>
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for Bihar BPSC 69th Pre Exam Recruitment(2023) </h1>
                     
                     <div>
                     <h1>Post Name :- BPSC (Bihar Public Service Commission) 69th Pre Exam Recruitment 2023.</h1>
                      <h2>Post Wise Vacancy Details :-</h2>
                      
                      <h3><li>District Coordinator :– 01 Posts</li></h3>
                      <h3><li>Superintendent Prohibition :– 02 Posts</li></h3>
                      <h3><li>State Tax Assistant Commissioner :– 03 Posts</li></h3>
                      <h3><li>District Planning Officer :– 06 Posts</li></h3>
                      <h3><li>Election Officer :– 04 Posts</li></h3>
                      <h3><li>Block Panchayat Raj Officer :– 29 Posts</li></h3>
                      <h3><li>Reed Officer :– 02 Posts</li></h3>
                      <h3><li>Revenue Officer :– 168 Posts</li></h3>
                      <h3><li>Bihar Education Service :– 02 Posts</li></h3>
                      <h3><li>IWelfare Officer :– 18 Posts</li></h3>
                      <h3><li>Child Development Project Officer :– 10 Posts</li></h3>
                      <h3><li>Financial Administrative Officer :– 100 Posts</li></h3>
                      <h3><li>IDeputy Superintend of Police :– 01 Posts</li></h3>
                      <h3><li>Total Post :– 349 Posts</li></h3>

                      <h2>Category Wise Vacancy Details :-</h2>
                      <h3><li>UR :– 155  Posts</li></h3>
                     <h3><li>EWS :– 32 Posts</li></h3>
                     <h3><li>BC :– 34 Posts</li></h3>
                     <h3><li>EBC :– 60 Posts</li></h3>  
                     <h3><li>BC Female :– 10 Posts</li></h3>
                     <h3><li>SC :– 51 Posts</li></h3>
                     <h3><li>ST :– 04 Posts</li></h3>
                     <h3><li>Total:– 349 Posts</li></h3>
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Respective ID Proof</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Offline Base Test(OBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Final Result</h2></div>
                        <div style={{display:"inline"}}><a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-26-03.pdf" target="_blank"><h2>Combined Competitive Examination</h2></a><a style={{paddingLeft:"20px"}}  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-26-01.pdf" target="_blank"><h2> Finance Administrative Officer</h2></a><a style={{paddingLeft:"15px"}}  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-26-02.pdf" target="_blank"><h2> Deputy Superintendent of Police </h2></a><a style={{paddingLeft:"15px"}}  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-26-04.pdf" target="_blank"><h2> Child Development Project Officer</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Interview Letter</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/FindCard.asp" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Interview Schedule</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-09-27-01.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Mains Result Date</h2></div>
                        <div style={{display:"inline"}}><a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-08-31-02.pdf" target="_blank"><h2>Combined Competitive Examination </h2></a><a style={{paddingLeft:"15px"}} href="https://www.bpsc.bih.nic.in/Notices/NB-2024-08-31-04.pdf" target="_blank"><h2> Finance Administrative Officer </h2></a><a style={{paddingLeft:"15px"}} href="https://www.bpsc.bih.nic.in/Notices/NB-2024-08-31-03.pdf" target="_blank"><h2> Deputy Superintendent of Police </h2></a><a style={{paddingLeft:"15px"}} href="https://www.bpsc.bih.nic.in/Notices/NB-2024-08-31-01.pdf" target="_blank"><h2> Child Development Project Officer</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Main Admit Card</h2></div>
                        <div><a href="https://onlinebpsc.bihar.gov.in/main/home" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Mains Apply Online</h2></div>
                        <div><a href="https://onlinebpsc.bihar.gov.in/main/home" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Pre Result Date</h2></div>
                        <div><a href="https://bpsc.bih.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Answer Key</h2></div>
                        <div><a href="https://bpsc.bih.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Pre Admit Card</h2></div>
                        <div><a href="https://onlinebpsc.bihar.gov.in/main/home" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Pre Apply Online</h2></div>
                        <div><a href="https://onlinebpsc.bihar.gov.in/main/home" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Date Extended Notice</h2></div>
                        <div><a href="https://bpsc.bih.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://bpsc.bih.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/Syllabus.htm" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/Archive/2023/NB-2023-09-30-01.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=coOEQ6YL5Ok" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default BPSC_2