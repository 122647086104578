import style from "./000_Content.module.css"
import a1 from "./Pic/Army_TGc_1.jpg"

const Army_TGC=()=>{
    document.title="Army TGC 140 Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Army Technical Graduate Courses(TGC) 140 Recruitment January 2025 Batch Apply Online Form</h1>
             
             <h2><b style={{color:"red"}}>Post-Name :-</b>Army Technical Graduate Course(TGC) 140 Recruitment</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>10/04/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>09/05/2024 Till 3PM</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The recruitment announcement for the Various Trade Technical Graduate Courses TGC 140 Batch January 2025 has been issued by Join Indian Army. Candidates who meet the eligibility requirements and are interested in the Army TGC 140 may apply online between April 10, 2024, and May 9, 2024. For details on the Army TGC 140 Exam 2024 age restriction, curriculum, institute-specific posts, selection process, and pay scale, see the Notification.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Army Technical Graduate Courses(TGC) 140 Apply Online Form(2025)</h1>
                    <h3>(Join Indian Army (Government of India))</h3>
                    <h2>Post Name –Army Technical Graduate Course(TGC) 140 Recruitment</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 30</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 10/04/2024</li></p>
                        <p><li><b>Last Date :-</b> 09/05/2024 Till 03 PM</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 09/05/2024</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 0/-</li></p>
                        <p><li><b>SC/ST :-</b> 0/-</li></p> 
                        <p><li><b>PH(Disability) :-</b> 0/-</li></p>  
                        </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit till 01/07/2024</h5></h1>  
                        <p><li><b>Minimum Age :-</b>20 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>27 Years</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Army Technical Graduate Courses (TGC)</div> 
                    <div className={style.q2}> 30</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Only Unmarried Male Candidates Are Eligible.</li>
                    <li>Passed / Appearing Engineering Degree in Related Trade / Branch.</li> <li>Final Year Appearing Candidate are also Eligible</li></div>
                    
                </div>
               
                <div id={style.p5}>
                    <h1>Vacancy Details for Army TGC Recruitment Form (2025) </h1>
                     
                     <div>
                     <h1>Post Name :- Army Technical Graduate Course(TGC) 140 Recruitment.</h1>
                      <h2>Engineering Stream Wise Vacancy Details 2025 :-</h2>
                     <h3><li>Civil / Building Construction Technology :– 07 Posts</li></h3>
                     <h3><li>Computer Science & Engineering / Computer Technology/ Info Tech/ M. Sc Computer Science :– 07 Posts</li></h3>
                     <h3><li>Mechanical / Production / Automobile / Equivalent :– 07 Posts</li></h3>
                     <h3><li>Electrical / Electrical & Electronics :– 03 Posts</li></h3>  
                     <h3><li>Electronics & Telecom/ Telecommunication/ Satellite Communication :– 04 Posts</li></h3>
                     <h3><li>Misc Engineering Stream :– 02 Posts</li></h3>
                     <h3><li>Automobile :– NA Posts</li></h3>
                     <h3><li>Textile:– NA Posts</li></h3> 
                     <h3><li>Electronics & Communication :– NA Posts</li></h3>
                     <h3><li>Telecommunication Engineering :– NA Posts</li></h3>       
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th&12th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://joinindianarmy.nic.in/Authentication.aspx" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                   <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://joinindianarmy.nic.in/writereaddata/Portal/NotificationPDF/TGC-140_NOTIFICATION__JAN_2025_.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://joinindianarmy.nic.in/writereaddata/Portal/NotificationPDF/TGC-140_NOTIFICATION__JAN_2025_.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=MVrRCpWhfH0&ab_channel=A24Knowledge" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://joinindianarmy.nic.in/Authentication.aspx" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default Army_TGC