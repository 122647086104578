import React from "react";
import style from "./Navcss.module.css"
import { Link } from "react-router-dom";

const Animation = () => {
  document.title = "Home | Job For Sarkari";

  return (
//     <div className={style.movingtextcontainer}>
//     <div className={style.movingtext}>This text is moving back and forth!</div>
//   </div>
     <>
    
    <div className={style.animation2}>
         <a href="https://www.facebook.com/profile.php?id=100092973634766" target="blank"> @Facebook Page</a><b>||</b>
         <a href="https://www.instagram.com/jobforsarkari/" target="blank"> @Instagram Page</a><b>||</b>
         <a href="https://www.youtube.com/@JobForSarkari" target="blank"> @YouTube</a><b>||</b>
         <a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="blank"> @Whatsapp</a><b>||</b>
         <a href="https://web.telegram.org/a/#-4725277915" target="blank"> @Telegram</a>
         
    </div>
    
    <div className={style.marqueecontainer}>
          <div className={style.marquee1}>
                  <Link to="/bpsctre1"><p className={style.animation1}>BPSC TRE 3.0 Final Result<b>||</b> </p></Link>
                  <Link to="/sbiclerk1"><p className={style.animation1}>SBI Clerk Online Form 2024 <b>||</b></p> </Link>
                  <Link to="/mppsc1"><p className={style.animation1}>MPPSC Pre Online Form 2025<b>||</b></p> </Link>
                  <Link to="/railwayassistantlocopilot1"><p className={style.animation1}>RRB Assistant Loco Pilot Admit Card</p></Link>    
          </div>
      </div><div className={style.marqueecontainer}>
              <div className={style.marquee2}>
              
                  <Link to="/rajasthantgtteacher1"><p className={style.animation1}>RPSC TGT Teacher Online Form 2024<b>||</b> </p></Link>
                  <Link to="/armytgc"><p className={style.animation1}>Army TGC 140 January 2025 Batch  <b>||</b></p> </Link>
                  <Link to="/biharpolytechnic"><p className={style.animation1}>BIHAR Polytechnic Apply   <b>||</b></p> </Link>
                  <Link to="/sscgd"><p className={style.animation1}>SSC GD Constable Recurment</p></Link>
              </div>
              
          </div>
          <div className={style.marqueecontainer}>
          <div className={style.marquee3}>
          
                  <Link to="/railwayrpf"><p className={style.animation1}>Railway RPF Apply Online <b>||</b> </p></Link>
                  <Link to="/armytgc"><p className={style.animation1}>Army TGC 140 January 2025 Batch  <b>||</b></p> </Link>
                  <Link to="/biharpolytechnic"><p className={style.animation1}>BIHAR Polytechnic Apply   <b>||</b></p> </Link>
                  <Link to="/sscgd"><p className={style.animation1}>SSC GD Constable Recurment</p></Link>
              </div>
            
            </div> </> 
  );
};
export default Animation
