import style from "./000_Content.module.css"
import a1 from "./Pic/IBPS_Clerk_1.jpg"

const IBPS_clerk_XIII=()=>{
    document.title="IBPS Clerk XIII Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>IBPS Clerk XIII Recruitment (2023)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> CRP Clerk III Recruitment 2023</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>01/07/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>21/07/2023</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>As per the latest official, Institute Of Banking Personnel Selection has publish the official notification for the recruitment of IBPS Clerk XIII Posts on the official website of the IBPS. Check More Details on Job For Sarakari As we all know that the IBPS conducts an online examination for the recruitment of Clerk every year. Same as, for the year 2023, IBPS has also release the notification for IBPS Clerk XIII Recruitment 2023. Online registration process for IBPS Clerk XIII Recruitment 2023 has started from 01 July 2023. Candidates must check the complete details for IBPS Clerk XIII Online Form 2023 which has given below.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>IBPS Clerk XIII Recruitment (2023)</h1>
                    <h3>(Institute Of Banking Personnel Selection)</h3>
                    <h2>Post Name – CRP Clerk III Recruitment 2023</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 4045</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 01/07/2023</li></p>
                        <p><li><b>Last Date :-</b> 21/07/2023</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 21/07/2023</li></p>
                        <p><li><b>Preliminary Exam Date :-</b>August / September 2023</li></p> 
                        <p><li><b> Preliminary Exam Admit Card :-</b>September 2023</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 850/-</li></p>
                        <p><li><b>SC/ST :-</b> 175/-</li></p> 
                        <p><li><b>PH(Disability) :-</b> 175/-</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Minimum Age :-</b>20 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>28 Years</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> IBPS Clerk</div> 
                    <div className={style.q2}> 4045</div> 
                    <div className={style.q3}> <li></li>Candidates having Bachelor Degree (Graduation Degree) in any Stream from a recognized University in India with Operating And Working Knowledge In Computer Systems will be eligible for this post.</div>
                    
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for IBPS Clerk III Application Form (2023) </h1>
                     
                     <div>
                     <h1>Post Name :- CRP Clerk III Exam (2023).</h1>
                      <h2>State Wise Vacancy Details 2023 :-</h2>
                      
                     <h3><li>Andaman & Nicobar :– 0 Posts</li></h3>
                     <h3><li>Andhra Pradesh :– 77 Posts</li></h3>
                     <h3><li>Arunachal Pradesh :– 06 Posts</li></h3>
                     <h3><li>Assam :– 77 Posts</li></h3>
                     <h3><li>Bihar :– 210 Posts</li></h3>
                     <h3><li>Chandigarh :– 06 Posts</li></h3>
                     <h3><li>Chhattisgarh :– 84 Posts</li></h3>
                     <h3><li>Dadar Nagar / Daman Diu :– 08 Posts</li></h3>
                     <h3><li>Delhi NCT :– 234 Posts</li></h3>
                     <h3><li>Goa  :– 36 Posts</li></h3>
                     <h3><li>Gujarat  :– 239 Posts</li></h3>
                     <h3><li>Haryana :– 174 Posts</li></h3>
                     <h3><li>Himachal Pradesh :– 81 Posts</li></h3>
                     <h3><li>Jammu & Kashmir :– 14 Posts</li></h3>
                     <h3><li>Jharkhand :– 52 Posts</li></h3>
                     <h3><li>Kerala :– 52 Posts</li></h3>
                     <h3><li>Karnataka :– 88 Posts</li></h3>
                     <h3><li>Lakshadeep :– 0 Posts</li></h3>
                     <h3><li>Madhya Pradesh :– 393 Posts</li></h3>
                     <h3><li>Maharashtra :– 527 Posts</li></h3>
                     <h3><li>Manipur :– 10 Posts</li></h3>
                     <h3><li>Meghalaya :– 01 Posts</li></h3>
                     <h3><li>Mizoram :– 01 Posts</li></h3>
                     <h3><li>Nagaland :– 03 Posts</li></h3>
                     <h3><li>Odisha :– 57 Posts</li></h3>
                     <h3><li>Puducherry :– 0 Posts</li></h3>
                     <h3><li>Punjab :– 321 Posts</li></h3>
                     <h3><li>Rajasthan :– 169 Posts</li></h3>
                     <h3><li>Sikkim :– 0 Posts</li></h3>
                     <h3><li>Tamil Naidu :– 142 Posts</li></h3>
                     <h3><li>Telangana :– 27 Posts</li></h3>
                     <h3><li>Tripura :– 15 Posts</li></h3>
                     <h3><li>Uttarakhand :– 26 Posts</li></h3>
                     <h3><li>Uttar Pradesh :– 674 Posts</li></h3>
                     <h3><li>West Bengal  :– 241 Posts</li></h3>     
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Left Thumb Impression</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crpcl13jun23/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://www.ibps.in/wp-content/uploads/Corrigendum-for-extension.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/ibps-clerk-syllabus-exam-pattern.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/ibps-clerk-previous-year-question-paper.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=fBA8I0pPdog" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.ibps.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default IBPS_clerk_XIII