import { BrowserRouter,Routes,Route } from "react-router-dom"
import Content1 from "./Component/Content1"
import Job from "./Component/Job"
import Logo from "./Component/Logo"
import Logo1 from "./Component/Logo1"
import Logo2 from "./Component/Logo2"
import Nav from "./Component/Nav"
import A00__Newjob from "./Component/Content/A00__Newjob"
import Footer from "./Component/Footer"
import A00_Admission_Form from "./Component/Content/A00_Admission_form"
import A00_Admit_Card from "./Component/Content/A00_Admit_Card"
import A00_Result from "./Component/Content/A00__Result"
import A00_Answer_Key from "./Component/Content/A00_Answer_Key"
import A00_Btech from "./Component/Content/A00_Btech"
import A00_Diploma from "./Component/Content/A00_Diploma"
import A00_Document_verification from "./Component/Content/A00_Document_verification"
import A00_syllabus from "./Component/Content/A00_syllabus"
import NCERT_Non_Teaching_Recruitment from "./Component/Data/NCERT_Non_Teaching_Recruitment"
import A001_Bank_job from "./Component/Content/A001_Bank_job"
import A001_Defence_job from "./Component/Content/A001_Defence_job"
import A001_Railway_job from "./Component/Content/A001_Railway_job"
import A001_Ssc_job from "./Component/Content/A001_Ssc_job"
import A001_Government_job from "./Component/Content/A001_Government_job"
import Footer1 from "./Component/Footer1"
import Animation from "./Component/Animation"
import ISRO_VSSC_Recruitment from "./Component/Data/ISRO_VSSC_Recruitment"
import SSC_MTS from "./Component/Data/SSC_MTS"
import SSC_Chsl from "./Component/Data/SSC_Chsl"
import UPSC_NDA from "./Component/Data/UPSC_NDA"
import UPSC_CDS from "./Component/Data/UPSC_CDS"
import IBPS_clerk_XIII from "./Component/Data/IBPS_clerk_XIII"
import AIIMS_NORCET from "./Component/Data/AIIMS_NORCET"
import Bihar_Police_bpssc_sub_inspector from "./Component/Data/Bihar_Police_bpssc_sub_inspector"
import UPPSC_Staff_Nurse from "./Component/Data/UPPSC_Staff_Nurse"
import SSC_GD from "./Component/Data/SSC_GD"
import BIHAR_STET from "./Component/Data/BIHAR_STET"
import BARC from "./Component/Data/BARC"
import CTET from "./Component/Data/CTET"
import UPSC_Combined_Medical from "./Component/Data/UPSC_Combined_Medical"
import Railway_RPF from "./Component/Data/Railway_RPF"
import BIHAR_Polytechnic from "./Component/Data/Bihar_Polytechnic"
import Army_TGC from "./Component/Data/Army_TGC"
import UPSC_IES_ISS from "./Component/Data/UPSC_IES_ISS"
import Allahabad_HighCourt_Research_Associate from "./Component/Data/Allahabad_HighCourt_Research_Associate"
import IBPS_PO_MT from "./Component/Data/IBPS_PO_MT"
import IDBI_Bank from "./Component/Data/IDBI_Bank"
import CLAT_UG_PG_Entrance from "./Component/Data/CLAT_UG_PG_Entrance"
import Indian_Coast_Guard_GD from "./Component/Data/Indian_Coast_Guard_GD"
import IRDAI_Assistant_Manager from "./Component/Data/IRDAI_Assistant_Manager"
import UPSC_Engineering_Services from "./Component/Data/UPSC_Engineering_Services"
import UPPSC_ATP from "./Component/Data/UPPSC_ATP"
import Nabard_Assistant_Manager from "./Component/Data/Nabard_Assistant_Manager"
import A0_Aadharcard from "./Component/Data/A0_Aadharcard"
import A0_Voter_Id_Card from "./Component/Data/A0_Voter_Id_Card"
import A0_Pan_Card from "./Component/Data/A0_Pan_Card"
import A0_Bihar_RTPS from "./Component/Data/A0_Bihar_RTPS"
import A0_UP_eDistrict from "./Component/Data/A0_UP_eDistrict"
import A0_Privacy_Policy from "./Component/Data/A0_Privacy_Policy"
import A0_Terms_Condition from "./Component/Data/A0_Terms_Condition"
import A0_Disclaimer from "./Component/Data/A0_Disclaimer"
import BPSC_1 from "./Component/Data/BPSC_1"
import Bihar_Police_Constable from "./Component/Data/Bihar_Police_Constable"
import BPSC_2 from "./Component/Data/BPSC_2"
import UPSC_Civil_Services from "./Component/Data/UPSC_Civil_Services"
import SBI_Clerk_1 from "./Component/Data/SBI_Clerk_1"
import BPSC_TRE_1 from "./Component/Data/BPSC_TRE_1"
import MPPSC_1 from "./Component/Data/MPPSC_1"
import Railway_Assistant_Loco_Pilot_1 from "./Component/Data/Railway_Assistant_Loco_Pilot_1"
import Rajasthan_TGT_Teacher_1 from "./Component/Data/Rajasthan_TGT_Teacher_1"
import ContactUs from "./Component/ContactUs"

const App=()=>{
    return(
        <div>
            <BrowserRouter>
            <Logo/>
            <Nav/>
            <Routes><Route path="/" element={<Logo2/>}></Route></Routes>
            <Routes><Route path="/" element={<Animation/>} ></Route></Routes>
            <Routes><Route path="/" element={<Logo1/>} ></Route></Routes>
            <Routes><Route path="/" element={<Job/>} ></Route></Routes>
            <Routes><Route path="/" element={<Content1/>} ></Route></Routes>
            <Routes><Route path="/" element={<Footer1/>} ></Route></Routes>


            {/* -------------------------Navigation--------------------------- */}

            <Routes>
                <Route path="/newjob" element={<A00__Newjob/>}></Route>
                <Route path="/admitcard" element={<A00_Admit_Card/>}></Route>
                <Route path="/result" element={<A00_Result/>}></Route>
                <Route path="/answerkey" element={<A00_Answer_Key/>}></Route>
                <Route path="/document" element={<A00_Document_verification/>}></Route>
                <Route path="/admission" element={<A00_Admission_Form/>}></Route>
                <Route path="/syllabus" element={<A00_syllabus/>}></Route>
                <Route path="/diploma" element={<A00_Diploma/>}></Route>
                <Route path="/btech" element={<A00_Btech/>}></Route>
                <Route path="/bankjob" element={<A001_Bank_job/>}></Route>
                <Route path="/defencejob" element={<A001_Defence_job/>}></Route>
                <Route path="/railwayjob" element={<A001_Railway_job/>}></Route>
                <Route path="/sscjob" element={<A001_Ssc_job/>}></Route>
                <Route path="/governmentjob" element={<A001_Government_job/>}></Route>
                <Route path="/animation" element={<Animation/>}></Route>
                <Route path="/privacypolicy" element={<A0_Privacy_Policy/>}></Route>
                <Route path="/termscondition" element={<A0_Terms_Condition/>}></Route>
                <Route path="/disclaimer" element={<A0_Disclaimer/>}></Route>
                <Route path="/contactus" element={<ContactUs/>}></Route>
            </Routes>
            
            {/* ---------------------------Document Verification----------------------------- */}

            <Routes>
            <Route path="/aadharcard" element={<A0_Aadharcard/>}></Route>
            <Route path="/voteridcard" element={<A0_Voter_Id_Card/>}></Route>
            <Route path="/pancard" element={<A0_Pan_Card/>}></Route>
            <Route path="/biharrtps" element={<A0_Bihar_RTPS/>}></Route>
            <Route path="/upedistrict" element={<A0_UP_eDistrict/>}></Route>
            </Routes>

            {/* ----------------------------------Data-------------------------------------- */}

            <Routes>
                <Route path="/aiimsnorcet" element={<AIIMS_NORCET/>}></Route>
                <Route path="/allahabadhighcourtresearchassociate" element={<Allahabad_HighCourt_Research_Associate/>}></Route>
                <Route path="/armytgc" element={<Army_TGC/>}></Route>
                <Route path="/barc" element={<BARC/>}></Route>
                <Route path="/biharpolicebpsscsubinspector" element={<Bihar_Police_bpssc_sub_inspector/>}></Route>
                <Route path="/biharpoliceconstable" element={<Bihar_Police_Constable/>}></Route>
                <Route path="/biharpolytechnic" element={<BIHAR_Polytechnic/>}></Route>
                <Route path="/biharstet" element={<BIHAR_STET/>}></Route>
                <Route path="/bpsc1" element={<BPSC_1/>}></Route>
                <Route path="/bpsc2" element={<BPSC_2/>}></Route>
                <Route path="/bpsctre1" element={<BPSC_TRE_1/>}></Route>
                <Route path="/clatugpgentrance" element={<CLAT_UG_PG_Entrance/>}></Route>
                <Route path="/ctet" element={<CTET/>}></Route>
                <Route path="/ibpsclerkxii" element={<IBPS_clerk_XIII/>}></Route>
                <Route path="/ibpspomt" element={<IBPS_PO_MT/>}></Route>
                <Route path="/idbibank" element={<IDBI_Bank/>}></Route>
                <Route path="/indiancoastguardgd" element={<Indian_Coast_Guard_GD/>}></Route>
                <Route path="/irdaiassistantmanager" element={<IRDAI_Assistant_Manager/>}></Route>
                <Route path="/isrovssc" element={<ISRO_VSSC_Recruitment/>}></Route>
                <Route path="/mppsc1" element={<MPPSC_1/>}></Route>
                <Route path="/nabardassistantmanager" element={<Nabard_Assistant_Manager/>}></Route>
                <Route path="/ncertnontechnical" element={<NCERT_Non_Teaching_Recruitment/>}></Route>
                <Route path="/railwayassistantlocopilot1" element={<Railway_Assistant_Loco_Pilot_1/>}></Route>
                <Route path="/railwayrpf" element={<Railway_RPF/>}></Route>
                <Route path="/rajasthantgtteacher1" element={<Rajasthan_TGT_Teacher_1/>}></Route>
                <Route path="/sbiclerk1" element={<SBI_Clerk_1/>}></Route>
                <Route path="/sscchsl" element={<SSC_Chsl/>}></Route>
                <Route path="/sscgd" element={<SSC_GD/>}></Route>
                <Route path="/sscmts" element={<SSC_MTS/>}></Route>
                <Route path="/uppscatp" element={<UPPSC_ATP/>}></Route>
                <Route path="/uppscstaffnurse" element={<UPPSC_Staff_Nurse/>}></Route>
                <Route path="/upsccds" element={<UPSC_CDS/>}></Route>
                <Route path="/upsccivilservices" element={<UPSC_Civil_Services/>}></Route>
                <Route path="/upsccombinedmedical" element={<UPSC_Combined_Medical/>}></Route>
                <Route path="/upscengineeringservices" element={<UPSC_Engineering_Services/>}></Route>
                <Route path="/upsciesiss" element={<UPSC_IES_ISS/>}></Route>
                <Route path="/upscnda" element={<UPSC_NDA/>}></Route>
            </Routes>
            <Footer/>
            </BrowserRouter>
        </div>
    )
}

export default App