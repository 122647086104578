import style from "./000_Content.module.css"
import a1 from "./Pic/NCERT_Non_Teaching_1.jpg"

const NCERT_Non_Teaching_Recruitment=()=>{
    document.title="NCERT Non Teaching Recruitment | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>NCERT Non Teaching Recruitment Various Post Online Form 2023</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> NCERT Non Teaching Recruitment 2023</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>29/04/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>19/05/2023</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>According to the official updates, National Council of Educational Research and Training (NCERT) had published the notification for the recruitment of Various Non Teaching Posts on the official website of the NCERT. As per the notification, Online application process for Various Non Teaching Posts Recruitment 2023 has started from 29 April 2023. There is a total of 347 vacancies has been issued in the NCERT Recruitment 2023 for Various Non Teaching Posts. Candidates must check the complete details for NCERT Non Teaching Recruitment 2023 which has given below.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>NCERT Non Teaching Recruitment 2023</h1>
                    <h3>(National Council of Educational Research and Training (NCERT))</h3>
                    <h2>Post Name – Various Non Teaching Posts Recruitment</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 347</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 29/04/2023</li></p>
                        <p><li><b>Last Date :-</b> 19/05/2023</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 19/05/2023</li></p>
                        <p><li><b>Exam Date :-</b>  Available Soon</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><b>General / OBC / EWS :-</b></p>         
                        <p><li><b>Level (10-12) :-</b> 1,500/-</li></p>  
                        <p><li><b>Level (6-7) :-</b> 1,200/-</li></p> 
                        <p><li><b>Level (2-5) :-</b> 1,000/-</li></p>    
                        <p><li><b>Sc/St :-</b> No Fee</li></p> 
                        <p><li><b>PH(Disability) :-</b> No Fee</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Any Where In India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 31/03/2023</h5></h1>  
                         
                        <p><li><b>Minimum Age :-</b> Depends on post</li></p> 
                        <p><li><b>Maximum Age :-</b> 27-50 Years Post Wise</li></p>   
                        <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> Bihar BTSC Pharmacist</div> 
                    <div className={style.q2}> 347</div> 
                    <div className={style.q3}> <li></li>Candidates who have passed their Class 10th, 12th Examination OR have Bachelor / Master Degree as per the eligibility of particular posts from recognized Board / University / Institute will be eligible for this recruitment.</div>
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for Bihar BTSC Pharmacist Recruitment (2023) </h1>
                     
                     <div>
                     <h1>Post Name :- Various Non Teaching Post (2023)</h1>
                     <h2>Category Wise Vacancy Details 2023 :-</h2>
                     
                     <h3><li>General :- 195</li></h3>
                     <h3><li>EWS :- 22</li></h3>
                     <h3><li>OBC :-89</li></h3>
                     <h3><li>SC :- 25</li></h3>
                     <h3><li>ST :- 16</li></h3>
                     <h3><li>BC(Female)</li></h3>
                     <h2>Category Wise Vacancy Details 2023 :-</h2>
                     <h3><li>Assistant – 46 Posts</li></h3>
                     <h3><li>Lower Division Clerk LDC – 84 Posts</li></h3>
                     <h3><li>Senior Library Attendant – 4 Posts</li></h3>
                     <h3><li>Driver Grade-III – 9 Posts</li></h3>
                     <h3><li>Film Assistant – 2 Posts</li></h3>
                     <h3><li>Floor Assistant – 4 Posts</li></h3>
                     <h3><li>Toucher Grade-I – 1 Posts</li></h3>
                     <h3><li>Photographer-Grade-II – 2 Posts</li></h3>
                     <h3><li>Projectionist – 1 Posts</li></h3>
                     <h3><li>TV Producer Grade-III – 6 Posts</li></h3>
                     <h3><li>Graphic Assistant Grade-II – 1 Posts</li></h3>
                     <h3><li>Receptionist – 1 Posts</li></h3>
                     <h3><li>Computer Operator Grade-III – 1 Posts</li></h3>
                     <h3><li>Proof Reader – 3 Posts</li></h3>
                     <h3><li>Engineering Assistant – 7 Posts</li></h3>
                     <h3><li>Photographer-Grade-I – 1 Posts</li></h3>
                     <h3><li>Script Writer – 1 Posts</li></h3>
                     <h3><li>Set Designer – 1 Posts</li></h3>
                     <h3><li>TV Producer Grade-II – 2 Posts</li></h3>
                     <h3><li>Sr. Proof Reader – 1 Posts</li></h3>
                     <h3><li>Store Keeper Grade-I – 5 Posts</li></h3>
                     <h3><li>Audio Radio Producer Grade-I – 1 Posts</li></h3>
                     <h3><li>Film Editor – 1 Posts</li></h3>
                     <h3><li>Production Manager – 1 Posts</li></h3>
                     <h3><li>Sound Recordist Grade-I – 1 Posts</li></h3>
                     <h3><li>TV Producer Grade-I – 1 Posts</li></h3>
                     <h3><li>Store Officer – 1 Posts</li></h3>
                     <h3><li>Senior Accountant – 2 Posts</li></h3>
                     <h3><li>Film Director – 1 Posts</li></h3>
                     <h3><li>Film Producer – 1 Posts</li></h3>
                     <h3><li>Senior Engineer – 1 Posts</li></h3>
                     <h3><li>Assistant Public Relation Officer – 1 Posts</li></h3>
                     <h3><li>Lab Assistant – 34 Posts</li></h3>
                     <h3><li>Store Keeper Grade-II – 17 Posts</li></h3>
                     <h3><li>Carpenter – 3 Posts</li></h3>
                     <h3><li>Dark Room Assistant – 1 Posts</li></h3>
                     <h3><li>Electrician – 2 Posts</li></h3>
                     <h3><li>Film Joiner – 1 Posts</li></h3>
                     <h3><li>Lightman – 2 Posts</li></h3>
                     <h3><li>Painter – 1 Posts</li></h3>
                     <h3><li>Toucher Grade-II – 1 Posts</li></h3>
                     <h3><li>Semi Professional Assistant – 8 Posts</li></h3>
                     <h3><li>Technician Grade-I – 13 Posts</li></h3>
                     <h3><li>Audio Radio Producer Grade-III – 4 Posts</li></h3>
                     <h3><li>Field Investigator – 1 Posts</li></h3>
                     <h3><li>Graphic Assistant Grade-I – 2 Posts</li></h3>
                     <h3><li>Junior Accountant – 6 Posts</li></h3>
                     <h3><li>Manager NIE Guest House & PG Hostel – 1 Posts</li></h3>
                     <h3><li>Production Assistant – 5 Posts</li></h3>
                     <h3><li>Editorial Assistant – 6 Posts</li></h3>
                     <h3><li>Marketing Executive – 4 Posts</li></h3>
                     <h3><li>Artist Gr-II – 1 Posts</li></h3>
                     <h3><li>Assistant Store Officer – 2 Posts</li></h3>
                     <h3><li>Professional Assistant – 7 Posts</li></h3>
                     <h3><li>Cameraman Grade-II – 6 Posts</li></h3>
                     <h3><li>Assistant Production Officer – 2 Posts</li></h3>
                     <h3><li>Assistant Editor (2 English, 2 Hindi and – 5 Posts)</li></h3>
                     <h3><li>Assistant Business Manager – 2 Posts</li></h3>
                     <h3><li>Artist Grade-I – 1 Posts</li></h3>
                     <h3><li>Technical Officer – 1 Posts</li></h3>
                     <h3><li>Assistant Engineer Grade-A – 6 Posts</li></h3>
                     <h3><li>Superintending Engineer – 1 Posts</li></h3>
                     <h3><li>Production Officer – 1 Posts</li></h3>
                     <h3><li>Editor – 4 Posts</li></h3>
                     <h3><li>Business Manager – 1 Posts</li></h3>
                     
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th,12th Documents</li></h3>
                    <h3><li>Respective Posts Degree Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://ncertnt.samarth.edu.in/index.php/site/login" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://ncert.nic.in/vacancies.php?ln=en" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://ncert.nic.in/syllabus.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://ncert.nic.in/model-question-papers.php?ln=en" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=9IfafF3jkVo&ab_channel=GyanDevHost" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://ncert.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div  style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div  style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default NCERT_Non_Teaching_Recruitment