import style from "./000_Content.module.css"
import a1 from "./Pic/Bihar_Polytechnic_1.jpg"

const BIHAR_Polytechnic=()=>{
    document.title="BIHAR Polytechnic Apply Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>BIHAR Polytechnic Apply Online Form (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>Diploma Certificate Entrance Competitive Examination (DCECE)</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>12/04/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>11/05/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>On its official website, the Bihar Combined Entrance Competitive Examination Board (BCECEB) has recently solicited online applications for the Diploma Certificate Entrance Competitive Examination, 2024. The Bihar Polytechnic Admission Form 2024 online registration period opened on April 12, 2024. The Bihar Polytechnic Admission 2024 application deadline is May 11, 2024. Applicants need to review all of the information provided below regarding Bihar Polytechnic Admission 2024.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>BIHAR Polytechnic Apply Online Form (2024)</h1>
                    <h3>(Bihar Combined Entrance Competitive Examination Board)</h3>
                    <h2>Post Name – Diploma Certificate Entrance Competitive Examination (DCECE)</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Seats - 36046 Seats</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 12/04/2024</li></p>
                        <p><li><b>Last Date :-</b> 11/05/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 14/05/2024</li></p>
                        <p><li><b>Edit / Correct Application :-</b>16-18/05/2024</li></p>
                        <p><li><b>Admit Card :-</b>16/06/2024</li></p> 
                        <p><li><b>PE Exam Date :-</b>22/06/2024</li></p>
                        <p><li><b>PM, PMM Exam Date :-</b>23/06/2024</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1> 
                        <p style={{color:"red"}}>Single Subject Group :-</p>
                        <p><li><b>General / OBC / EWS :-</b> 750/-</li></p>
                        <p><li><b>SC/ST/PH :-</b> 480</li></p> 
                        <p style={{color:"red"}}>Double Subject Group :-</p>
                        <p><li><b>General / OBC / EWS :-</b> 850/-</li></p>
                        <p><li><b>SC/ST/PH :-</b> 530</li></p> 
                        <p style={{color:"red"}}>Triple Subject Group :-</p>
                        <p><li><b>General / OBC / EWS :-</b> 950/-</li></p>
                        <p><li><b>SC/ST/PH :-</b> 630</li></p> 
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Bihar</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>PE :-</b>Age is not necessary</li></p> 
                        <p><li><b>PMD :-</b>15 - 30 Years</li></p> 
                        <p><li><b>PM(GNM,ANM ):-</b>17 - 35 Years</li></p>
                        <p><li><b>PM(Other Subject):-</b>17 - 32 Years</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Diploma Certificate Entrance Examination</div> 
                    <div className={style.q2}> 30</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>10th standard pass from a recognized board with English and Science subjects.</li><li>10+2 Intermediate Exam with Science (Physics, Chemistry, English, Math/Bio)</li>
                    <li>10+2 Intermediate Exam with Science (Physics, Chemistry, Biology) with 40% marks</li> <li>10+2 Intermediate Exam with 40% marks with English as a subject</li></div>
                    
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for BIHAR Polytechnic (2024) </h1>
                     
                     <div>
                     <h1>Post Name :-  Diploma Certificate Entrance Competitive Examination (DCECE)</h1>
                      <h2>Course Wise Seats Distribution For Bihar Polytechnic Admission 2024:-</h2>
                      
                     <h3><li>{`1.)`}Polytechnic Engineer (PE) :- 16170 Seats</li></h3>
                     <h3><li>{`2.)`}PE In Private Polytechnic :- 5340 Seats</li></h3> 
                     <h3><li>{`3.)`}Para Medical (Secondary Level) :- 690 Seats</li></h3>
                     <h3><li>{`4.)`}For Para Medical (Intermediate Level) In Government Institutes For ANM Course :- 4530 Seats</li></h3> 
                     <h3><li>{`5.)`}For Para Medical (Intermediate Level) In Non Government Institutes For ANM Course :- 2997 Seats</li></h3>
                     <h3><li>{`6.)`}For Para Medical (Intermediate Level) In Government Institutes For For Grade – A Nursing Course :- 1538 Seats</li></h3> 
                     <h3><li>{`7.)`}For Para Medical (Intermediate Level) In Non Government Institutes For Grade – A Nursing Course :- 1986 Seats</li></h3>
                     <h3><li>{`8.)`}Para Medical (Intermediate Level) :- 2795 Seats</li></h3>       
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Matriculation (Class 10th) exam mark sheet</li></h3>
                    <h3><li>Intermediate (Class 12th) certificate and mark sheet</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://bceceboardapl.bihar.gov.in/bceceboardapl/dcece_2024/#no-back-button" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://bceceboard.bihar.gov.in/pdf_Adv/ADV_DC24_01.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.adda247.com/school/bihar-polytechnic/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.mynearexam.in/2021/09/pdf-bihar-polytechnic-previous-year.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=aPSmPmoz10o&ab_channel=DSHELPINGFOREVER" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://bceceboard.bihar.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default BIHAR_Polytechnic