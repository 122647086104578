import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/Syllabus.jpg"
const A00_syllabus=()=>{
    document.title="Syllabus | JobForSarkari"
    return(
        <div id={style.list}>
        <h1>Syllabus</h1>
        <h3>New Job issued on 5 April 2023</h3>
        <li><Link to="/idbibank" style={{textDecoration:"none"}}><b className={style.a}>IDBI Bank Executive Sales and Operations ESO Syllabus</b></Link></li>
        <li><Link to="/ibpspomt" style={{textDecoration:"none"}}><b className={style.a}>IBPS Probationary Officer / Management Trainee PO / MT Recruitment Syllabus</b></Link></li>
        <li><Link to="/csbcbiharpoliceconstable" style={{textDecoration:"none"}}><b className={style.a}>CSBC Bihar Police Constables Recruitment Syllabus</b></Link></li>
        <h3>New Job issued on 4 April 2023</h3>
        <li><Link to="/rrbassistantlocopilot" style={{textDecoration:"none"}}><b className={style.a}>RRB (Railway Recruitment Board) Assistant Loco Pilot ALP Syllabus</b></Link></li>
        <li><Link to="/clatugpgentrance" style={{textDecoration:"none"}}><b className={style.a}>CLAT (Common Law Admission Test) UG / PG Entrance Syllabus</b></Link></li>
        <li><Link to="/indiancoastguardgd" style={{textDecoration:"none"}}><b className={style.a}> Join Indian Coast Guard ICG Yantrik / Navik GD Syllabus</b></Link></li>
        <div><img src={img} alt="" style={{width:"100%", height:"400px"}} /></div>
    </div>
    )
}

export default A00_syllabus