import style from "./000_Content.module.css"
import a1 from "./Pic/UPSC_Civil_Service_1.jpg"

const UPSC_Civil_Services=()=>{
    document.title="UPSC Civil Services IAS/IFS Main Result | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>UPSC IAS/IFS  Apply Online Form (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> UPSC Civil Services IAS / Forest Service IFS Recruitment 2024, Download Mains Result 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b> 14/02/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b> 06/03/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b> UPSC Civil Services IAS / Forest Service IFS Recruitment 2024 | Union Public Service Commission (UPSC). The UPSC IAS / IFS 2024 Exam is open to those who would want to apply online . To find out about the eligibility for the recruitment, the role, the selection process, the age restriction, the pay scale, and other details, read the announcement.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>UPSC IAS / IFS Apply Online Form (2024)</h1>
                    <h3>(Union Public Service Commission(UPSC))</h3>
                    <h2>Post Name – Indian Civil Services IAS / Forest Service IFS Examination</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 1206</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b>14/02/2024</li></p>
                        <p><li><b>Last Date :-</b> 06/03/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 06/03/2024</li></p>
                        <p><li><b>Correction Date :-</b> 07-13 March 2024</li></p> 
                        <p><li><b>Pre Admit Date :-</b> 07/06/2024</li></p>
                        <p><li><b>Pre Exam Date :-</b> 16/06/2024</li></p>
                        <p><li><b>Pre Result Date :-</b> 01/07/2024</li></p>
                        <p><li><b>Mains Admit Date (IAS) :-</b> 13/09/2024</li></p>
                        <p><li><b>Mains Exam Date :-</b> 20-29 September 2024</li></p>
                        <p><li><b>Mains Admit Card (IFS) :-</b> 14/11/2024</li></p>
                        <p><li><b>Mains Exam Result (IAS) :-</b> 09/12/2024</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS (Pre):-</b> 100/-</li></p>
                        <p><li><b>General / OBC / EWS (Mains):-</b> 200/-</li></p>
                        <p><li><b>SC/ST :-</b> No Fee</li></p> 
                        <p><li><b>PH(Disability) :-</b> No Fee</li></p> 
                        <p><li><b>All Female :-</b> No Fee</li></p> 
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit till 01/08/2024</h5></h1>  
                        <p><li><b>Minimum Age :-</b> 21 Years.</li></p> 
                        <p><li><b>Maximum Age :-</b>  32 Years.</li></p> 
                       <p><li>Additional age relaxation as per UPSC Civil Services IAS / Forest Service IFS recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Indian Administrative Service (IAS)</div> 
                    <div className={style.q2}> 1056</div> 
                    <div className={style.q3}> <li> Bachelor Degree in Any Stream in Any Recognized University in India.</li><li>For More Eligibility Details. Please Read the Oficial Notification</li></div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Indian Forest Service (IFS)</div> 
                    <div className={style.q2}> 150</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Bachelor Degree as one of Subject Animal Husbandry & Veterinary Science, Botany, Chemistry, Geology, Mathematics, Physics, Statistics and Zoology, Agriculture or Equivalent.</li><li>For More Eligibility Details. Please Read the Oficial Notification</li></div>
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for UPSC Civil Services IAS / Forest Service IFS (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- UPSC Civil Services IAS / Forest Service IFS Examination.</h1>
                      <h2>Post Wise Vacancy Details 2024 :-</h2>
                      
                     <h3><li>Indian Administrative Service (IAS) :– 1056 Posts</li></h3>
                     <h3><li>Indian Forest Service (IFS) :– 150 Posts</li></h3>
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts Wise Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(Pre Only)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>OTR Registration</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://rpsc.rajasthan.gov.in/Static/RecruitmentAdvertisements/52C8C180FC8444E397C1FAAEF0947C96.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://testbook.com/upsc-ies-iss/syllabus" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://upsc.gov.in/examinations/previous-question-papers?field_exam_name_value=indian+economic+service" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=UfdKN0cyuJk&ab_channel=GyanDevHost" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://upsc.gov.in/whats-new" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div ><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2 >@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default UPSC_Civil_Services