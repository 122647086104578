import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/Results.jpg"

const A00_Result=()=>{
    document.title="Results | Job For Sarkari"
    return(
        <div id={style.list}>
           
        <h1>Result</h1>
        <h3>New Job issued on 26 December 2024</h3>
        <li><Link to="/bpsctre1" style={{textDecoration:"none"}}><b className={style.a}>Bihar BPSC (Bihar Public Service Commission) TRE 3.0 (TGT & PGT) Final Result 2024</b></Link></li>
        
        <h3>New Job issued on 29 November 2024</h3>
        <li><Link to="/nabardassistantmanager" style={{textDecoration:"none"}}><b className={style.a}>Nabard Assistant Manager Grade A Recruitment, Download Result Phase I OR Phase II Mains Result (2024)</b></Link></li>
        <li><Link to="/uppscatp" style={{textDecoration:"none"}}><b className={style.a}>UPPSC Assistant Town Planner ATP Recruitment 2023, Published Final Result (2024)</b></Link></li>
        <li><Link to="/upscengineeringservices" style={{textDecoration:"none"}}><b className={style.a}>UPSC (Union Public Service Commission) Engineering Services Examination, Publish Final Result  2024</b></Link></li>
        
        <h3>New Job issued on 27 November 2024</h3>
        <li><Link to="/ibpspomt" style={{textDecoration:"none"}}><b className={style.a}>IBPS Probationary Officer / Management Trainee PO / MT Recruitment 2024,Pre Result Score Card</b></Link></li>
        <li><Link to="/bpsc2" style={{textDecoration:"none"}}><b className={style.a}>Bihar BPSC (Bihar Public Service Commission) 69th Pre Exam Recruitment 2023 Final Result 2024</b></Link></li>
        <h3>New Job issued on 26 November 2024</h3>
        <li><Link to="/irdaiassistantmanager" style={{textDecoration:"none"}}><b className={style.a}>IRDAI (Insurance Regulatory and Development Authority of India) Assistant Recruitment, Phase I Result (2024)</b></Link></li>
       
        <h3>New Job issued on 28 October 2024</h3>
        <li><Link to="/allahabadhighcourtresearchassociate" style={{textDecoration:"none"}}><b className={style.a}>Allahabad High Court Research Associates Recruitment 2024 Shortlisted Candidate List</b></Link></li>
        
        <h3>New Job issued on 23 December 2023</h3>
        <li><Link to="/barc" style={{textDecoration:"none"}}><b className={style.a}>Bhabha Atomic Research Centre (BARC) Various Posts Result 2023</b></Link></li>
        
        <h3>New Job issued on 14 December 2023</h3>
        <li><Link to="/sscchsl" style={{textDecoration:"none"}}><b className={style.a}>SSC CHSL Tier 1 Additional Result 2023</b></Link></li>
        
        <h3>New Job issued on 08 December 2023</h3>
        <li><Link to="/upsccivilservices" style={{textDecoration:"none"}}><b className={style.a}>UPSC Civil Services IAS/IFS Main Result</b></Link></li>
        <li><Link to="/sscmts" style={{textDecoration:"none"}}><b className={style.a}>SSC MTS/Havaldar Tier I Result Final Vacancy Details 2023</b></Link></li>
        <div><img src={img} alt="" style={{width:"100%", height:"400px"}} /></div>          
        </div>
    )
}

export default A00_Result