import style from "./000_Content.module.css"
import a1 from "./Pic/IDBI_Bank_1.jpg"

const IDBI_Bank=()=>{
    document.title="IDBI Bank ESO Admit Card | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>IDBI Bank Executive Sales and Operations ESO Recruitment, Admit Card (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> IDBI Bank Executive Sales and Operations ESO Recruitment 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>07/11/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>16/11/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>Executive Sales and Operations (ESO) Recruitment 2024 at IDBI Bank Limited. Candidates can download their admit card if they are interested in taking the IDBI ESO exam. For information on recruitment criteria, position details, selection process, age restriction, salary range, and other details, see the IDBI Bank ESO position notice 2024.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>IDBI Bank Executive ESO Recruitment (2024)</h1>
                    <h3>(Industrial Development Bank of India(IDBI))</h3>
                    <h2>Post Name – IDBI Bank Executive ESO online form (2024)</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 1000</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 07/11/2024</li></p>
                        <p><li><b>Last Date :-</b> 16/11/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 16/11/2024</li></p>
                        <p><li><b>Admit Card :-</b>26/11/2024</li></p>
                        <p><li><b>Exam Date :-</b>01/12/2024</li></p> 
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 1050/-</li></p>
                        <p><li><b>SC/ST :-</b> 250/-</li></p> 
                        <p><li><b>PH(Disability) :-</b> 250/-</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Minimum Age :-</b>20 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>25 Years</li></p> 
                       <p><li>Additional Age Relaxation Per IDBI Bank's Hiring Guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> IDBI Bank Executive ESO</div> 
                    <div className={style.q2}> 1000</div> 
                    <div className={style.q3}> <li></li> Candidates having Bachelor Degree (Graduation Degree) in any Stream from a recognized University in India, will be eligible for this post.</div>
                    
                </div>
                
                <div id={style.p5}>
                    <h1>IDBI Bank Executive ESO Recruitment  (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- IDBI Bank Executive Sales and Operations Recruitment (2024).</h1>
                     <h2>Cast Wise Vacancy Details 2024 :-</h2>
                     <h3><li>UR :– 448  Posts</li></h3>
                     <h3><li>OBC :– 231 Posts</li></h3>
                     <h3><li>EWS :– 100 Posts</li></h3> 
                     <h3><li>SC :– 127 Posts</li></h3>
                     <h3><li>ST :– 94 Posts</li></h3>
                     <h3><li>Total:– 1000 Posts</li></h3>   
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Respective ID Proof</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Admit Card</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/idbesooct24/oecl_nov24/login.php?appid=152f99c8169892685c425083a7c7ee49" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/idbesooct24/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://www.idbibank.in/pdf/careers/ESO-2025-26.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/idbi-syllabus.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://testbook.com/idbi-executive/previous-year-papers" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=e4cTN-jhtXQ" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.ibps.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default IDBI_Bank