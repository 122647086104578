import style from "./000_Content.module.css";
import a1 from "./Pic/MPPSC_1.jpg";

const MPPSC_1=()=>{
    document.title="Madhya Pradesh State Service Exam | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Madhya Pradesh State Service Exam Recruitment Online Form (2025)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> Madhya Pradesh State Service Exam SSE Pre Recruitment (2025)</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>03/01/2025</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>17/01/2025 </h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The Madhya Pradesh State Service Exam (MP SSE) 2025 is a competitive exam conducted by MPPSC for various administrative posts in the state government, such as Deputy Collector, DSP, Naib Tehsildar, and others. Candidates must hold a bachelor’s degree to be eligible. The selection process includes Prelims, Mains, and Interview. The age limit is generally 21-40 years, with relaxations as per government rules. Applications can be submitted online via the official MPPSC website. This exam is a gateway to prestigious roles in Madhya Pradesh's civil services.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                        <h1>Madhya Pradesh State Service Exam SSE Pre Recruitment 2025</h1>
                        <h3>(Madhya Pradesh Public Service Commission (MPPSC))</h3>
                        <h2>Post Name – MP State Service Exam SSE Pre Recruitment 2025</h2>
                    </div>
                <div id={style.p2}>
                    <h1>Total Posts - 158</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 03/01/2025</li></p>
                        <p><li><b>Last Date :-</b> 17/01/2025 </li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 17/01/2025</li></p>
                        <p><li><b>Correction Date :-</b> 19/01/2025</li></p> 
                        <p><li><b>Pre Exam Date:-</b> 16/02/2025</li></p>
                        <p><li><b>Admit Card :-</b> 11/02/2025</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / Other State :-</b> 500/-</li></p>
                        <p><li><b>Reserve Category(MP) :-</b> 250/-</li></p> 
                        <p><li><b>Portal Charge :-</b> 40/-</li></p> 
                        <p><li><b>Correction Charge :-</b> 50/-</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Madhya Pradesh</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 01/01/2025</h5></h1>  
                        <p><b style={{color:"red"}}>For Uniformed Post</b></p>
                        <p><li><b>Minimum Age :-</b>21 Years</li></p> 
                        <p><li><b>Maximum Age :-</b> 33-38 Years Cast Wise</li></p> 
                        <p><b style={{color:"red"}}>For Non-Uniformed Post</b></p>
                        <p><li><b>Minimum Age :-</b>21 Years</li></p> 
                        <p><li><b>Maximum Age :-</b> 40-45 Years Cast Wise</li></p>   
                        <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> MP State Services Exam</div> 
                    <div className={style.q2}> 158</div> 
                    <div className={style.q3}> <li></li>Bachelor Degree in Any Stream in Any Recognized University in India.</div>
                </div>
               
                <div id={style.p5}>
                    <h1>Vacancy Details for Madhya Pradesh State Services Recruitment (2025) </h1>
                     
                     <div>
                     <h1>Post Name :-  Madhya Pradesh State Services Examination (2025)</h1>
                      <h2>Post Wise Vacancy Details 2025 :-</h2>
                     <h3><li>State Administrative Service Deputy District Magistrate :– 10 Posts</li></h3>
                     <h3><li>Deputy Superintendent of Police :– 22 Posts</li></h3>
                     <h3><li>Madhya Pradesh Finance Service :– 01 Posts</li></h3>
                     <h3><li>Commercial Tax Inspector :– 01 Posts</li></h3>
                     <h3><li>Chief Municipal Officer Category B :– 02 Posts</li></h3>
                     <h3><li>Assistant Director Industry / Manager :– 03 Posts</li></h3>
                     <h3><li>Public Relations Department Assistant Director :– 02 Posts</li></h3>
                     <h3><li>Labour Department, Assistant Welfare Commissioner :– 01 Posts</li></h3>
                     <h3><li>Panchayat and Rural Development Department, Additional Assistant Development Commissioner :– 10 Posts</li></h3>
                     <h3><li>Block Development Officer BDO :– 03 Posts</li></h3>
                     <h3><li>Women and Child Development Department, Child Development Project Officer :– 65 Posts</li></h3>
                     <h3><li>Women and Child Development Department, Development Block Women Empowerment Officer :– 06 Posts</li></h3>
                     <h3><li>Naib Tehsildar :– 03 Posts</li></h3>
                     <h3><li>Commercial Tax Inspector :– 05 Posts</li></h3>
                     <h3><li>Urban Development and Housing Department, Chief Municipal Officer :– 02 Posts</li></h3>
                     <h3><li>Co-operative Department, Co-operative Inspector / Co-operative Extension Officer :– 07 Posts</li></h3>
                     <h3><li>MPPSC Junior Account Officer :– 5 Posts</li></h3>
                     <h3><li>Finance Department, Madhya Pradesh Subordinate Accounts Service :– 14 Posts</li></h3>
                     <h3><li>Total :– 158 Posts</li></h3>
                     <h2>Category Wise Vacancy Details 2025 :-</h2>
                     <h3><li>  UR :– 38 Posts</li></h3>
                     <h3><li> SC :– 24 Posts</li></h3>
                     <h3><li>ST :– 48 Posts</li></h3>
                     <h3><li>OBC :– 35 Posts</li></h3>
                     <h3><li>EWS :– 13 Posts</li></h3>
                     <h3><li>Total :– 158 Posts</li></h3>
                     
                     </div>
                </div>
                <div id={style.p55}>
    <h1>How To Apply Madhya Pradesh State Service Exam Online Form</h1>
    <div>
        <h1>Here, we describe Step by Step how to fill the MP State Service Exam Form Online :-</h1>
        <h2>Step 1{`)`} :- Check Eligibility </h2>
        <h3>
            <ol>
                <li>Age Limit: Typically 21-40 years (age relaxation as per MP government rules).</li>
                <li>Educational Qualification: A Bachelor's degree in any discipline from a recognized university.</li>
            </ol>
        </h3>
        <h2>Step 2{`)`} :- Visit the Official Website </h2>
        <h3>
            <ol>
                <li>Go to the official MPPSC website: <a href="https://mppsc.mp.gov.in/">https://mppsc.mp.gov.in/</a>.</li>
            </ol>
        </h3>
        <h2>Step 3{`)`} :- Register as a New User (if not already registered) </h2>
        <h3>
            <ol>
                <li>On the home page, click on "New Registration" under the Login section.</li>
                <li>Fill in the required details for One-Time Registration :-</li>
                <ul>
                    <li>Basic Details (Name, Father's Name, Date of Birth, etc.)</li>
                    <li>Contact Information (Email ID and Mobile Number)</li>
                    <li>Permanent Address</li>
                </ul>
                <li>Upload your scanned photograph and signature as per the specified dimensions :-</li>
                <ul>
                    <li>Photograph: 50-100 KB (JPEG format).</li>
                    <li>Signature: 20-50 KB (JPEG format).</li>
                </ul>
                <li>Submit the details and note down the Registration ID and Password sent to your registered email/mobile.</li>
            </ol>
        </h3>
        <h2>Step 4{`)`} :- Log In to Your Account </h2>
        <h3>
            <ol>
                <li>Use your Registration ID and Password to log in to the MPPSC portal.</li>
                <li>Change your password if prompted.</li>
            </ol>
        </h3>
        <h2>Step 5{`)`} :- Apply for MP State Service Exam </h2>
        <h3>
            <ol>
                <li>After logging in, go to the “Apply” section.</li>
                <li>Select the link for the Madhya Pradesh State Service Exam.</li>
                <li>Fill in the required details in the application form :-</li>
                <ul>
                    <li>Examination Center Preference</li>
                    <li>Educational Qualification</li>
                    <li>Category (General, OBC, SC/ST, etc.)</li>
                    <li>Other personal details.</li>
                </ul>
                <li>Verify all details carefully before submitting.</li>
            </ol>
        </h3>
        <h2>Step 6{`)`} :- Upload Documents </h2>
        <h3>
            <ol>
                <li>Upload your scanned photograph and signature (if not done during registration).</li>
                <li>Follow the dimensions and file size guidelines mentioned on the form.</li>
            </ol>
        </h3>
        <h2>Step 7{`)`} :- Pay the Application Fee </h2>
        <h3>
            <ol>
                <li>The fee for General candidates is typically ₹500, while candidates from SC/ST/OBC (non-creamy layer) and PwD categories pay ₹250.</li>
                <li>Payment can be made via :-</li>
                <ul>
                    <li>Online: Credit/Debit Card, Net Banking, UPI.</li>
                    <li>Offline: Generate a Challan and pay at the designated bank.</li>
                </ul>
            </ol>
        </h3>
        <h2>Step 8{`)`} :- Submit the Application </h2>
        <h3>
            <ol>
                <li>After completing all steps, click on the “Final Submit” button.</li>
                <li>Take a printout of the application form for future reference.</li>
            </ol>
        </h3>
    </div>
</div>

                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://www.mponline.gov.in/Portal/Examinations/MPPSC/2025/SS25/Declaration.aspx" target="_blank"><h2>Registration |</h2></a><a href="https://www.mponline.gov.in/Portal/Examinations/MPPSC/2025/SS25/UNPaidApplications.aspx" target="_blank"><h2>| Login</h2></a></div>
                        
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://mppsc.mp.gov.in/uploads/advertisement/Advt__State_Service_Examination_2024_Dated_30_12_2023.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://mppsc.mp.gov.in/Syllabus" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://mppsc.mp.gov.in/Oldquestionpaper" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=CdSY6x800aQ" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://ssc.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}
export default MPPSC_1
