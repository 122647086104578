import style from "./000_Content.module.css"
import a1 from "./Pic/SSC_MTS_1.jpg"

const SSC_MTS=()=>{
    document.title="SSC MTS Result | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>SSC Multi-Tasking Staff MTS and Havaldar Final Vacancy Details And Result (2023)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> SSC Multi-Tasking Staff (MTS) and Havaldar Recruitment (2023)</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>30/06/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>21/07/2023</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The announcement for the 2023 Havaldar (CBIC & CBN) and Multi-Tasking (Non-Technical) Staff Examination has been made public by the Staff Selection Commission (SSC). The answer key is available to all candidates who took the CBT Tier I exam. To find out more about the SSC MTS Recruitment Exam 2023 eligibility, age restriction, selection process, syllabus, pattern, pay scale, and other details, read the notification.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>SSC Multi-Tasking Staff (MTS) and Havaldar Recruitment (2023)</h1>
                    <h3>(Staff Selection Commission (SSC))</h3>
                    <h2>Post Name – SSC Multi-Tasking Staff MTS and Havaldar Recruitment</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 1558</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 30/06/2023</li></p>
                        <p><li><b>Last Date :-</b> 21/07/2023</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 22/07/2023</li></p>
                        <p><li><b>Correction Date :-</b> 26 To 28 July 2023</li></p> 
                        <p><li><b>Paper I Exam Date:-</b> 01 To 14 September 2023</li></p>
                        <p><li><b>Answer Key Paper I :-</b> 17/09/2023</li></p>
                        <p><li><b>Result Paper I :-</b> 07/11/2023</li></p>
                        <p><li><b>Paper II Exam Date :-</b> Notified Soon</li></p>
                        <p><li><b>Final Vacancy Details :-</b> 08/12/2023</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 100/-</li></p>
                        <p><li><b>SC/ST :-</b> No Fee</li></p> 
                        <p><li><b>All Category Female :-</b> No Fee</li></p> 
                        <p><li><b>PH(Disability) :-</b> No Fee</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Any Where In India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 01/08/2023</h5></h1>  
                         
                        <p><li><b>Minimum Age :-</b>18 Years</li></p> 
                        <p><li><b>Maximum Age :-</b> 25-27 Years Post Wise</li></p>   
                        <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> Multi-Tasking Staff (MTS)</div> 
                    <div className={style.q2}> 1198</div> 
                    <div className={style.q3}> <li></li>Class 10 High School Exam Passed in Any Recognized Board in India.</div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Havaldar</div> 
                    <div className={style.q2}> 360</div> 
                    <div className={style.q3}> <li></li>Class 10 High School Exam Passed in Any Recognized Board in India.</div>
                </div>
                <div id={style.p5}>
                    <h1 style={{color:"green"}}>Physical Details for SSC MTS Constable Application Form 2023</h1>
                    <div>
                    <h3 style={{color:"red"}}>Height :-</h3>
                    <h3><li>Male :- 157.5 cms</li></h3>
                    <h3><li>Female :- 152 cms</li></h3>
                    <h3 style={{color:"red"}}>Running :-</h3>
                    <h3><li>Male :- 1600 meter in 15 min</li></h3>
                    <h3><li>Female :- 1km in 20 min</li></h3>
                    <h3><li>Chest Male :- 81-86 cms</li></h3>
                </div>
                </div>
                <div id={style.p5}>
                    <h1>Vacancy Details for SSC MTS Multi Tasking Staff & Havaldar Recruitment (2023) </h1>
                     
                     <div>
                     <h1>Post Name :- Multi Tasking Staff(MTS) & Havaldar Post (2023)</h1>
                      <h2>Category Wise Vacancy Details 2023 :-</h2>
                     <h3><li>Multi Tasking Staff(MTS) :– 1198 Posts</li></h3>
                     <h3><li>Havaldar :– 360 Posts</li></h3>
                     </div>
                </div>
                <div id={style.p55}>
                    <h1>How To Apply SSC MTS Online Form</h1>
                    <div>
                        <h1>Here, We are describe Step By Step how to fill the SSC MTS Form Online :-</h1>
                        <h2>Step 1{`)`} :- Check Eligibility </h2>
                        <h3><ol>
                              <li>Age Limit: Typically 18-27 years (age relaxation for reserved categories as per government norms).</li>
                                <li>Educational Qualification: Minimum qualification is 10th pass (Matriculation) or equivalent.</li>
                        </ol></h3>
                        <h2>Step 2{`)`} :- Visit the Official Website </h2>
                        <h3><ol><li>Go to the official SSC website:- <a href="https://ssc.gov.in/">https://ssc.gov.in/</a>.</li></ol></h3>
                        <h2>Step 3{`)`} :- Register as a New User (if not already registered) </h2>
                        <h3>
                        <ol>
                            <li>On the home page, click on "Register Now" under the Login section.</li>
                            <li>Fill in the required details for One-Time Registration :-</li>
                            <ul>
                             <li>Basic Details (Name, Father's Name, Mother's Name, Date of Birth, etc.)</li>
                             <li>Contact Information (Email ID and Mobile Number)</li>
                             <li>Permanent Address</li>
                          </ul>
                          <li>Upload your scanned photograph and signature as per the specified dimensions :-</li>
                          <ul>
                              <li>Photograph: 20-50 KB (JPEG format).</li>
                             <li>Signature: 10-20 KB (JPEG format).</li>
                             
                          </ul>
                          <li>Submit the details and note down the Registration ID and Password sent to your registered email/mobile.</li>
                        </ol>  
                        </h3>
                        <h2>Step 4{`)`} :- Log In to Your Account </h2>
                        <h3>
                        
                        <ol>
                        <li>Use your Registration ID and Password to log in to the SSC portal.</li>
                        <li>Change your password if prompted.</li>
                        </ol></h3>
                        <h2>Step 5{`)`} :- Apply for SSC MTS Exam </h2>
                       <h3> <ol>
                        <li>After logging in, go to the “Apply” section.</li>
                        <li>Select the link for SSC MTS Examination.</li>
                        <li>Fill in the required details in the application form :-</li>
                        <ul><li>Examination Center Preference</li></ul>
                        <ul><li>Educational Qualification</li></ul>
                        <ul><li>Category (General, OBC, SC/ST, etc.)</li></ul>
                        <ul><li>Other personal details.</li></ul>
                        <li>Verify all details carefully before submitting.</li>
                        </ol>
                        </h3>
                        <h2>Step 6{`)`} :- Upload Documents </h2>
                        <h3>
                            <ol>
                                <li>Upload your scanned photograph and signature (if not done during registration).</li>
                                <li>Follow the dimensions and file size guidelines mentioned on the form.</li>
                            </ol>
                        </h3>
                        <h2>Step 7{`)`} :- Pay the Application Fee </h2>
                        <h3>
                            <ol>
                                <li>The fee for General/OBC candidates is usually ₹100, while candidates from SC/ST, PwD, and female categories are exempt.</li>
                                <li>Payment can be made via :-</li>
                                <ul>
                                    <li>Online: Credit/Debit Card, Net Banking, UPI.</li>
                                    <li>Offline: Generate a Challan and pay at the designated bank.</li>
                                </ul>
                            </ol>
                        </h3>
                        <h2>Step 8{`)`} :- Submit the Application </h2>
                        <h3>
                            <ol>
                                <li>After completing all steps, click on the “Final Submit” button.</li>
                                <li>Take a printout of the application form for future reference.</li>
                            </ol>
                        </h3>
                    </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    
                    <div>
                        <div><h2>Download Final Vacancy Details</h2></div>
                        <div><a href="https://ssc.nic.in/SSCFileServer/PortalManagement/UploadedFiles/Final_Vacancy_08122023.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Havaldar Result</h2></div>
                        <div><a href="https://ssc.nic.in/SSCFileServer/PortalManagement/UploadedFiles/ROLL-H_07112023.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Answer Key</h2></div>
                        <div><a href="https://ssc.digialm.com/EForms/configuredHtml/2207/82676/login.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Exam Dates Notification</h2></div>
                        <div><a href="https://ssc.nic.in/SSCFileServer/PortalManagement/UploadedFiles/Notice_29032023.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://ssc.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://ssc.nic.in/SSCFileServer/PortalManagement/UploadedFiles/Final_Notice_MTS2023_30062023.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/ssc-mts-syllabus.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/ssc-mts-previous-year-question-paper.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=5YwdjpV4I5s&ab_channel=SUNITECH%26EDUCATION" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://ssc.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default SSC_MTS