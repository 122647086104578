import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/Goverment_Job.jpg"

const A001_Government_job=()=>{
    document.title="Government Jobs | Job For Sarkari"
    return(
        <div id={style.list}>
        <h1 id={style.gov}>Government Jobs</h1>
        <h3>New Job issued on 01 January 2025 </h3>
        <li><Link to="/mppsc1" style={{textDecoration:"none"}}><b className={style.a}>Madhya Pradesh State Service Exam Online Form 2025</b></Link></li>
        <h3>New Job issued on 27 December 2025 </h3>  
        <li><Link to="/bpsctre1" style={{textDecoration:"none"}}><b className={style.a}>Bihar BPSC (Bihar Public Service Commission) TRE 3.0 (TGT & PGT) Final Result 2024</b></Link></li>
        <h3>New Job issued on 26 December 2024</h3>
        <li><Link to="/rajasthantgtteacher1" style={{textDecoration:"none"}}><b className={style.a}>Rajasthan RPSC Senior Teacher Grade II TGT Teacher Recruitment Apply Online 2024 </b></Link></li>
            
        <h3>New Job issued on 29 November 2024</h3> 
        <li><Link to="/bpsc1" style={{textDecoration:"none"}}><b className={style.a}>Bihar BPSC (Bihar Public Service Commission) 70th Pre Exam Recruitment, Admit Card 2024</b></Link></li>
        <li><Link to="/nabardassistantmanager" style={{textDecoration:"none"}}><b className={style.a}>Nabard Assistant Manager Grade A Recruitment, Download Result Phase I OR Phase II Mains Result (2024)</b></Link></li>
        <li><Link to="/uppscatp" style={{textDecoration:"none"}}><b className={style.a}>UPPSC Assistant Town Planner ATP Recruitment 2023, Published Final Result (2024)</b></Link></li>
       
        <h3>New Job issued on 27 November 2024</h3>
        <li><Link to="/bpsc2" style={{textDecoration:"none"}}><b className={style.a}>Bihar BPSC (Bihar Public Service Commission) 69th Pre Exam Recruitment 2023 Final Result 2024</b></Link></li>
         
        <h3>New Job issued on 26 October 2024</h3>
        <li><Link to="/csbcbiharpoliceconstable" style={{textDecoration:"none"}}><b className={style.a}>CSBC Bihar Police Constables Recruitment 2023 Result, PET Admit Card 2024</b></Link></li>
        <li><Link to="/irdaiassistantmanager" style={{textDecoration:"none"}}><b className={style.a}>IRDAI (Insurance Regulatory and Development Authority of India) Assistant Recruitment, Phase I Result (2024)</b></Link></li>
       
        <h3>New Job issued on 26 October 2024</h3>
        <li><Link to="/allahabadhighcourtresearchassociate" style={{textDecoration:"none"}}><b className={style.a}>Allahabad High Court Research Associates Recruitment 2024 Shortlisted Candidate List</b></Link></li>
        <h3>New Job issued on 08 April 2024</h3>
        <li><Link to="/ctet" style={{textDecoration:"none"}}><b className={style.a}>CTET July 2024 Correction / Edit Form for Paper I & Paper II 2024</b></Link></li>
        
        <h3>New Job issued on 23 December 2023</h3>
        <li><Link to="/barc" style={{textDecoration:"none"}}><b className={style.a}>Bhabha Atomic Research Centre (BARC) Various Posts Result 2023</b></Link></li>
        
        <h3>New Job issued on 14 December 2023</h3>
        <li><Link to="/biharstet" style={{textDecoration:"none"}}><b className={style.a}>Bihar State Eligibility Test BSEB STET Apply Online Form for Paper I and II (2024)</b></Link></li>
      
        <h3>New Admit Card issued on 08 December 2023</h3>
        <li><Link to="/uppscstaffnurse" style={{textDecoration:"none"}}><b className={style.a}>UPPSC Staff Nurse Admit Card 2023</b></Link></li>
        
        <h3>New Job issued on 09 November 2023</h3>
        <li><Link to="/biharpolicebpsscsubinspector" style={{textDecoration:"none"}}><b className={style.a}>Bihar Police BPSSC Sub Inspector Recruitment 2023 Re Upload Photo Signature</b></Link></li>
            
        <h3>New Job issued on 7 August 2023</h3>
        <li><Link to="/aiimsnorcet" style={{textDecoration:"none"}}><b className={style.a}>AIIMS Norcet 5th Recruitment Online Form 2023</b></Link></li>

        <h3>New Job issued on 02 May 2023</h3>
        <li><Link to="/isrovssc" style={{textDecoration:"none"}}><b className={style.a}>ISRO VSSC Recruitment Various Post Online Form 2023</b></Link></li>
            
        <h3>New Job issued on 29 April 2023</h3>
        <li><Link to="/ncertnontechnical" style={{textDecoration:"none"}}><b className={style.a}>NCERT Non Teaching Recruitment  Various Post Online Form 2023</b></Link></li>
        <div><img src={img} alt="" style={{width:"100%", height:"400px"}} /></div>
        </div>
    )
}
export default A001_Government_job