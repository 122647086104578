import style from "./000_Content.module.css"
import a1 from "./Pic/BPSC_TRE_1.webp"


const BPSC_TRE_1=()=>{
    document.title="BPSC TRE 3.0 Final Result | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Bihar BPSC TRE (Bihar School Teacher) 3.0 Recruitment, Final Result (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>Bihar BPSC TRE (Bihar School Teacher) 3.0 Recruitment Online Form 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>10/02/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>26/02/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The Bihar Public Service Commission (BPSC) conducts the Teacher Recruitment Exam (TRE) to recruit qualified teachers for various posts in government schools across Bihar. The exam is designed to assess candidates' knowledge, teaching aptitude, and understanding of subjects relevant to the teaching profession. It includes both written tests and interviews, depending on the post. Clearing the BPSC TRE can lead to a stable and prestigious teaching career in the state’s education sector.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Bihar BPSC TRE 3.0 Recruitment Online Form (2024)</h1>
                    <h3>(Bihar Public Service Commission (BPSC))</h3>
                    <h2>Post Name – Bihar BPSC TRE 3.0 Recruitment 2024</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 86391</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b>10/02/2024</li></p>
                        <p><li><b>Last Date :-</b> 26/02/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 26/02/2024</li></p>
                        <p><li><b>Re Exam Date :-</b> 19-22 July 2024</li></p> 
                        <p><li><b>Admit Card :-</b>09/07/2024</li></p>
                        <p><li><b>Question Booklet :-</b> 10/08/2024</li></p>
                        <p><li><b>OMR Sheet :-</b> 15-22 August 2024</li></p>
                        <p><li><b>Answer Key (Class 1-5) :-</b>28/08/2024</li></p>
                        <p><li><b>Answer Key (Class 6-8) :-</b> 30/08/2024</li></p>
                        <p><li><b>Answer Key (Class 9-12) :-</b> 06/09/2024</li></p>
                        <p><li><b>Response/ OMR Sheet:-</b> 18/09/2024</li></p> 
                        <p><li><b>Result (Class 1-5 & 6-8) :-</b>15/11/2024</li></p>
                        <p><li><b>Result (Class 9-10 & 11-12):-</b> 26/12/2024</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 750/-</li></p>
                        <p><li><b>SC/ST :-</b> 200/-</li></p> 
                        <p><li><b>PH(Disability) :-</b> 200/-</li></p> 
                        <p><li><b>Female(Bihar Domicile) :-</b> 200/-</li></p> 
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Bihar</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit till 01/08/2023</h5></h1>  
                        <p><li><b>Minimum Age (Primary Teacher) :-</b> 18 Years.</li></p> 
                        <p><li><b>Minimum Age (TGT / PGT Teacher):-</b>  21 Years.</li></p> 
                        <p><li><b>Maximum Age Male:-</b>  37 Years.</li></p> 
                        <p><li><b>Maximum Age Female:-</b>  40 Years.</li></p> 
                       <p><li>Additional age relaxation as per BPSC recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Primary Teacher (Class 1-5)</div> 
                    <div className={style.q2}> 28026</div> 
                    <div className={style.q3} style={{display:"inline"}}> 
                        <li>Senior Secondary with 50% Marks and 2 Year Diploma in Elementary Education <b>OR</b></li> 
                        <li> Senior Secondary with 45% Marks and 2 Year Diploma in Elementary Education (AS PER NCTE Norms 2002) <b>OR</b></li> 
                        <li> Senior Secondary with 50% Marks and 4 Year B.L.Ed Course <b>OR</b></li>  
                        <li> Senior Secondary with 50% Marks and 2 Year Diploma in Elementary Education (Special) <b>OR</b></li>  
                        <li> Bachelor Degree in Any Stream with 2 Year Diploma in Elementary Education</li> 
                        <li><b>CTET / BTET Paper I Exam Passed</b></li> 
                        <li>Read the Official Notification For More Eligibility Details.</li></div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Middle School Teacher (Class 6-8)</div> 
                    <div className={style.q2}> 19057</div> 
                    <div className={style.q3} style={{display:"inline"}}> 
                        <li>Bachelor Degree with 2 Year Diploma in Elementary Education <b>OR</b></li> 
                        <li> Bachelor / Master Degree with 50% Marks with B.Ed <b>OR</b></li> 
                        <li> Bachelor Degree with 45% Marks and B.Ed (NCTE Norms)<b>OR</b></li>  
                        <li> Bachelor Degree with 50% Marks and BA BED and B.Sc Ed <b>OR</b></li>  
                        <li> Bachelor Degree with 50% Marks and B.Ed Special <b>OR</b></li> 
                        <li> Master Degree with 55% Marks and 3 Year B.Ed - M.Ed Course.</li> 
                        <li><b>CTET / BTET Paper II Exam Passed</b></li> 
                        <li>Read the Official Notification For More Eligibility Details.</li></div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> TGT Teacher (Class 9-10)</div> 
                    <div className={style.q2}> 16870</div> 
                    <div className={style.q3} style={{display:"inline"}}> 
                        <li>Bachelor / Master Degree in Related Subject with Minimum 50% Marks and B.Ed Degree <b>OR</b></li> 
                        <li> Bachelor / Master Degree in Related Subject with Minimum 45% Marks (As per 2002 Norms)  and B.Ed Degree <b>OR</b></li>  
                        <li> 4 Year Degree in BAEd / BScEd</li> 
                        <li><b>STET Paper I Exam Passed</b></li> 
                        <li>Read the Official Notification For More Eligibility Details.</li></div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> TGT Teacher Special (Class 9-10) </div> 
                    <div className={style.q2}> 65</div> 
                    <div className={style.q3} style={{display:"inline"}}> 
                        <li>Bachelor / Master Degree in Related Subject with Minimum 50% Marks and B.Ed Degree <b>OR</b></li> 
                        <li> Bachelor / Master Degree in Related Subject with Minimum 45% Marks (As per 2002 Norms)  and B.Ed Degree <b>OR</b></li> 
                        
                        <li>4 Year Degree in BAEd / BScEd</li> 
                        <li><b>STET Paper I Exam Passed</b></li> 
                        <li>Read the Official Notification For More Eligibility Details.</li></div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> PGT Teacher (Class 11-12)</div> 
                    <div className={style.q2}> 22373</div> 
                    <div className={style.q3} style={{display:"inline"}}> 
                        <li>Master Degree in Related Subject with Minimum 50% Marks and B.Ed Degree<b>OR</b></li> 
                        <li> Master Degree in Related Subject with Minimum 45% Marks (As per 2002 Norms)  and B.Ed Degree<b>OR</b></li> 
                        <li>Master Degree in Related Subject with 4 Year Degree in BAEd / BScEd <b>OR</b></li> 
                        <li> Master Degree with 55% Marks and B.Ed – Med 3 Year Degree</li> 
                        <li><b>STET Paper II Exam Passed</b></li> 
                        <li>Read the Official Notification For More Eligibility Details.</li></div>
                </div>
                <div id={style.p55}>
    <h1>How To Apply BPSC TRE Online Form</h1>
    <div>
        <h1>Here, we describe step by step how to fill the BPSC TRE Online Form:</h1>
        
        <h2>Step 1{`)`} :- Check Eligibility</h2>
        <h3>
            <ol>
                <li>Age Limit: Typically 18-37 years (age relaxation for reserved categories as per Bihar government norms).</li>
                <li>Educational Qualification: Graduation along with a relevant teaching qualification (e.g., B.Ed., D.El.Ed.).</li>
            </ol>
        </h3>
        
        <h2>Step 2{`)`} :- Visit the Official Website</h2>
        <h3>
            <ol>
                <li>Go to the official BPSC website: <a href="https://bpsc.bih.nic.in/">https://bpsc.bih.nic.in/</a>.</li>
            </ol>
        </h3>
        
        <h2>Step 3{`)`} :- Register as a New User (if not already registered)</h2>
        <h3>
            <ol>
                <li>On the homepage, click on the "Apply Online" link.</li>
                <li>Fill in the required details for registration:</li>
                <ul>
                    <li>Basic Details (Name, Date of Birth, Email ID, Mobile Number, etc.)</li>
                    <li>Educational Qualifications</li>
                    <li>Category (General, OBC, SC/ST, etc.)</li>
                </ul>
                <li>Create a strong password and note down your registration ID and password sent to your registered email and mobile.</li>
            </ol>
        </h3>
        
        <h2>Step 4{`)`} :- Log In to Your Account</h2>
        <h3>
            <ol>
                <li>Use your Registration ID and Password to log in to the BPSC portal.</li>
                <li>Complete your profile by providing additional details if required.</li>
            </ol>
        </h3>
        
        <h2>Step 5{`)`} :- Fill the Application Form</h2>
        <h3>
            <ol>
                <li>Navigate to the "Apply Online" section and select the BPSC TRE application link.</li>
                <li>Fill in the application form with accurate details:</li>
                <ul>
                    <li>Preferred Exam Centers</li>
                    <li>Educational Qualifications</li>
                    <li>Teaching Subject</li>
                    <li>Category and Reservation Details</li>
                </ul>
                <li>Verify all details before proceeding to the next step.</li>
            </ol>
        </h3>
        
        <h2>Step 6{`)`} :- Upload Documents</h2>
        <h3>
            <ol>
                <li>Upload your scanned photograph and signature as per the specified guidelines:</li>
                <ul>
                    <li>Photograph: 20-50 KB (JPEG format).</li>
                    <li>Signature: 10-20 KB (JPEG format).</li>
                </ul>
                <li>Ensure the documents are clear and meet the file size requirements.</li>
            </ol>
        </h3>
        
        <h2>Step 7{`)`} :- Pay the Application Fee</h2>
        <h3>
            <ol>
                <li>The fee structure is usually as follows:</li>
                <ul>
                    <li>General/OBC: ₹600</li>
                    <li>SC/ST/PwD/Women: ₹150</li>
                </ul>
                <li>Payment Methods:</li>
                <ul>
                    <li>Online: Credit/Debit Card, Net Banking, UPI</li>
                    <li>Offline: Generate a Challan and pay at the designated bank.</li>
                </ul>
            </ol>
        </h3>
        
        <h2>Step 8{`)`} :- Submit the Application</h2>
        <h3>
            <ol>
                <li>After verifying all details, click on the “Final Submit” button.</li>
                <li>Download and print the application form for future reference.</li>
            </ol>
        </h3>
    </div>
</div>

                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Respective ID Proof</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Offline Base Test(OBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Download Result (Class 11-12)</h2></div>
                        <div style={{display:"inline"}}>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-26-35.pdf" target="_blank"><h2 >Hindi </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-26-36.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>URDU </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-26-37.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>English  </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-26-38.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Sanskrit </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-26-39.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Bangla </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-26-40.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Maithili  </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-26-41.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Arabic  </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-26-42.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Persian  </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-26-43.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Bhojpuri </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/" target="_blank"><h2 style={{paddingTop:"5px"}}>Other </h2></a>
                        </div>
                    </div>
                    <div>
                        <div><h2>Download Result (Class 9-10)</h2></div>
                        <div style={{display:"inline"}}><a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-16-02.pdf" target="_blank"><h2>English  </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-16-03.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Hindi </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-16-04.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>URDU </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-16-05.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Bangla </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-16-06.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Sanskrit </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-16-07.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Arabic </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-16-08.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Farsi </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-16-09.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Science </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-16-10.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Mathematics </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-12-16-11.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Social Science </h2></a>
                        </div>
                    </div>
                    <div>
                        <div><h2>Download Result (Class 6-8)</h2></div>
                        <div style={{display:"inline"}}><a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-15-05.pdf" target="_blank"><h2>English  </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-15-06.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Hindi </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-15-07.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>URDU </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-15-08.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Sanskrit </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-15-09.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Math and Science </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-15-10.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Social Science </h2></a>
                        </div>
                    </div>
                    <div>
                        <div><h2>Download Result (Class 1-5)</h2></div>
                        <div style={{display:"inline"}}><a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-15-01.pdf" target="_blank"><h2>General (Edu Dept) </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-15-02.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>General (SC ST Dept) </h2></a>
                        <a  href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-15-03.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>URDU </h2></a>
                        <a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-11-15-04.pdf" target="_blank"><h2 style={{paddingTop:"5px"}}>Bangla</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Check Answer Key(Class 9-12)</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Check Answer Key(Class 6-8)</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Check Answer Key(Class 1-5)</h2></div>
                        <div><a href="https://doc.sarkariresults.org.in/SarkariResult_BPSC_TRE_3_Class1-5_AnswerKey_NB-2024-08-28-02.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Admit Card</h2></div>
                        <div><a href="https://onlinebpsc.bihar.gov.in/main/home" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Exam Date Notice</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-06-11-01.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://onlinebpsc.bihar.gov.in/main/home" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Check Vacancy Details</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-02-23-10.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/Notices/NB-2024-02-07-06.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.adda247.com/teaching-jobs-exam/bihar-teacher-syllabus/?srsltid=AfmBOoqlMvVAZIH_04gXy1OufIBhmBmPSHdWObux9zMJHhyuyYiIBKmk" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.adda247.com/teaching-jobs-exam/bpsc-tre-3-0-question-papers-2024/?srsltid=AfmBOoq55BTOHBJo57iHkBe5GWSFrmND6-16IH0cDUy1QRiyTlHQmmok" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=t4F14up4eTU" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default BPSC_TRE_1