import style from "./000_Content.module.css"
import a1 from "./Pic/Bihar_Constable_1.jpg"

const Bihar_Police_Constable=()=>{
    document.title="CSBC Bihar Police Constables Recruitment | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>CSBC Bihar Police Constables Recruitment 2023 Result, PET Admit Card 2024</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>CSBC Bihar Police Constables Recruitment 2023</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>20/06/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>20/07/2023</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>Notification Advt. No. 01/2023: For the selection of Constables in the Bihar Police, Bihar Special Armed Police, and other units, Government of Bihar, has been announced by the Central Selection Board of Constables (CSBC). The results can be downloaded by those candidates who are enrolled with a vacancy. For details on the CSBC Constable 2023 age restriction, curriculum, institute-specific positions, selection process, and compensation scale, view the advertising.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>CSBC Bihar Police Constables Recruitment (2023)</h1>
                    <h3>(Central Selection Board of Constable)</h3>
                    <h2>Post Name –CSBC Bihar Police Constable Recruitment 2023</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 21391</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 20/06/2023</li></p>
                        <p><li><b>Last Date :-</b> 20/07/2023</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 20/07/2023</li></p>
                        <p><li><b>Admit Card :-</b> 31/07/2024</li></p>
                        <p><li><b>Exam Date :-</b> 07,11,18,21,25,28/08/2024</li></p>
                        <p><li><b>Result  Date :-</b> 14/11/2024</li></p>
                        <p><li><b>PET Admit Card :-</b> 21/07/2024</li></p>
                        <p><li><b>PET Exam Date :-</b> 09/12/2024</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 675/-</li></p>
                        <p><li><b>Other State :-</b> 675/-</li></p> 
                        <p><li><b>SC/ST :-</b> 180/-</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p>
                        </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Bihar</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit till 01/08/2022</h5></h1>  
                        <p><li><b>Minimum Age :-</b>18 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>25 Years</li></p> 
                       <p><li>According to Bihar Police Constable Advt No. 01/2023 Recruitment 2023, there is an additional age relaxation., please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Bihar Police Constable</div> 
                    <div className={style.q2}> 21391</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li> Male, Female Candidates Are Eligible.</li>  <li>Passed 10+2 Intermediate Exam In Any Recognized Board in India.</li></div>
                </div>
                
                <div id={style.p5}>
                    <h1>Physical Eligibility for Bihar Police Constable </h1>
                    <div><h3><b style={{color:"green"}}>{`1.)`}Male</b></h3>
                    <h3><li>Height :– UR/BC:-165CMS & EBC/SC/ST:-160CMS</li></h3>
                     <h3><li>Chest :– UR/BC/EBC:-81-86CMS & SC/ST:-79-84CMS</li></h3>
                     <h3><li>Running :– 1.6 KM in 6 Minutes</li></h3>
                     <h3><li>High Jump :– 4 Feet</li></h3>
                     <h3><li>Gola Fek :– 16 Pound Through 16 Feet</li></h3>
                    <h3><b style={{color:"green"}}>{`2.)`}Female</b></h3>
                    <h3><li>Height:– 155CMS</li></h3>
                     <h3><li>Chest :– NA</li></h3>
                     <h3><li>Running :– 1 KM in 5 Minutes</li></h3>
                     <h3><li>High Jump :– 4 Feet</li></h3>
                     <h3><li>Gola Fek :– 12 Pound Through 12 Feet</li></h3></div>
                </div>
                <div id={style.p5}>
                    <h1>Vacancy Details for CSBC Bihar Constable (2023) </h1>
                     
                     <div>
                     <h1>Post Name :-CSBC Bihar Police Constables Recruitment.</h1>
                     <h2>Category Wise Vacancy Details 2024 :-</h2>
                     <h3><li>UR :– 8556  Posts</li></h3>
                     <h3><li>EWS :– 2140 Posts</li></h3>
                     <h3><li>BC :– 2570 Posts</li></h3>
                     <h3><li>EBC :– 3842 Posts</li></h3>  
                     <h3><li>BC Female :– 655 Posts</li></h3>
                     <h3><li>SC :– 3400 Posts</li></h3>
                     <h3><li>ST :– 228 Posts</li></h3>
                     <h3><li>Total:– 21391 Posts</li></h3>      
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Respective ID Proof</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Download Admit Card (PET)</h2></div>
                        <div><a href="https://apply-csbc.com/PetAdmV123/applicationIndex" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Notice (PET)</h2></div>
                        <div><a href="https://csbc.bihar.gov.in/Advt/Notice-PET-21-11-2024.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Result</h2></div>
                        <div><a href="https://doc.sarkariresults.org.in/SarkariResult.COM_Bihar_Police_Constable_Results-01-2023-Written-Exam-for-PET.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Admit Card</h2></div>
                        <div><a href="https://csbc.bihar.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://apply-csbc.com/CSBC_CT_V3_01_2023/applicationIndex" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                   <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://www.sarkariresults.org.in/wp-content/uploads/2023/06/sarkariresult.com-bihar-police-constable-notification-2023.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/blog/bihar-police-constable-syllabus-2023" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=tuKwa49ot7U" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://csbc.bihar.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default Bihar_Police_Constable