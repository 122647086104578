import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/Diploma_IT.jpg"
const A00_Diploma=()=>{
    document.title="Diploma | Job For Sarkari"
    return(
        <div id={style.list}>
        <h1>Diploma/IT</h1>
        <h3>New Job issued on 5 April 2024</h3>
        <li><Link to="/rrbassistantlocopilot" style={{textDecoration:"none"}}><b className={style.a}>RRB (Railway Recruitment Board) Assistant Loco Pilot ALP Online Form</b></Link></li>
        <li><Link to="/uppscstaffnurse" style={{textDecoration:"none"}}><b className={style.a}>UPPSC Staff Nurse Apply Online Form 2024</b></Link></li>
        <li><Link to="/indiancoastguardgd" style={{textDecoration:"none"}}><b className={style.a}>Join Indian Coast Guard ICG Yantrik / Navik GD Online form 2024</b></Link></li>
        <h3>New Job issued on 4 April 2024</h3>
        <li><Link to="/biharpolytechnic" style={{textDecoration:"none"}}><b className={style.a}>BIHAR Polytechnic Apply Online Form 2024 </b></Link></li>
        <li><Link to="/biharstet" style={{textDecoration:"none"}}><b className={style.a}>Bihar State Eligibility Test BSEB STET Apply Online Form for Paper I and II 2024</b></Link></li>
        <li><Link to="/isrovssc" style={{textDecoration:"none"}}><b className={style.a}>ISRO VSSC Recruitment Various Post Online Form 2023</b></Link></li>
        <div><img src={img} alt="" style={{width:"100%", height:"400px"}} /></div>
    </div>
    )
}

export default A00_Diploma