import style from "./000_Content.module.css"
import a1 from "./Pic/Bihar_Sub_Inspector_1.jpg"

const Bihar_Police_bpssc_sub_inspector=()=>{
    document.title="Bihar_Police_bpssc_sub_inspector | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Bihar Police BPSSC Sub Inspector Recruitment 2023 Admit Card</h1>
             
             <h2><b style={{color:"red"}}>Post-Name :-</b>Bihar Police BPSSC Sub Inspector Recruitment 2023</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>05/10/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>05/11/2023</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>Bihar Police Subordinate Services Commission (BPSSC) has released the Sub Inspector SI notification for the recruitment of 1275 Post 2023. All the candidates who are interested in this BPSSC Advt No 02/2023 Police Sub-Inspectors in Home (Police) Dept., Govt. of Bihar Recruitment 2023 Can Download Exam Admit Card. See the advertisement for information related to age limit, syllabus, PET, selection procedure, pay scale in BPSSC Daroga Recruitment 2023.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Bihar Police BPSSC Sub Inspector Recruitment (2023)</h1>
                    <h3>(Bihar Police Subordinate Services Commission (BPSSC))</h3>
                    <h2>Post Name –  Sub Inspector, SI</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 1275</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 05/10/2023</li></p>
                        <p><li><b>Last Date :-</b> 05/11/2023</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 05/11/2023</li></p>
                        <p><li><b>Re Upload Photo / Signature :-</b>09-14 November 2023</li></p> 
                        <p><li><b> Admit Card :-</b>01/12/2023</li></p>
                        <p><li><b> Exam Date:-</b>17/12/2023</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS /Other State:-</b> 700/-</li></p>
                        <p><li><b>SC/ST :-</b> 400/-</li></p> 
                        <p><li><b>Female Candidate :-</b> 400/-</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Bihar</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Minimum Age :-</b>20 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>37 Years for Male</li></p> 
                        <p><li><b>Maximum Age :-</b>40 Years for Female</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Sub Inspector SI</div> 
                    <div className={style.q2}> 1275</div> 
                    <div className={style.q3}> <li></li>Candidates having Bachelor Degree (Graduation Degree) in any recognized University in India.</div>
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for Bihar Police Sub Inspector (2023) </h1>
                     
                     <div>
                     <h1>Post Name :- Sub Inspector SI (2023).</h1>
                      <h2>Cast Wise Vacancy Details 2023 :-</h2>
                      
                     <h3><li>UR :– 441 Posts</li></h3>
                     <h3><li>EWS :– 111 Posts</li></h3>
                     <h3><li>EBC :– 238 Posts</li></h3>
                     <h3><li>OBC :– 107 Posts</li></h3>
                     <h3><li>BC Female :– 82 Posts</li></h3>
                     <h3><li>SC :– 275 Posts</li></h3>
                     <h3><li>ST :– 16 Posts</li></h3>
                     <h3><li>Transgender:– 05 Posts</li></h3>
                        
                     </div>
                </div>
                <div id={style.p5}>
                    <h1>Physical Eligibility Details for Bihar Police Sub Inspector (2023) </h1>
                     
                     <div>
                     <h1>Male</h1>
                      <h2>{`1.)`} Gen/OBC:-</h2>
                      
                     <h3><li>Height :– 165CMS</li></h3>
                     <h3><li>Chest :– 81-86 CMS</li></h3>
                     <h3><li>Running :– 1.6 KM in 6 Min 30 Second</li></h3>
                     <h3><li>High Jump :– 4 Feet</li></h3>
                     <h3><li>Long Jump :– 12 Feet</li></h3>
                     <h3><li>Gola Fek :– 16 Pound Through 16 Feet</li></h3>
                     <h2>{`2.)`} Others:-</h2>
                      
                      <h3><li>Height :– 160CMS</li></h3>
                      <h3><li>Chest :– 79-84 CMS</li></h3>
                      <h3><li>Running :– 1.6 KM in 6 Min 30 Second</li></h3>
                      <h3><li>High Jump :– 4 Feet</li></h3>
                      <h3><li>Long Jump :– 12 Feet</li></h3>
                      <h3><li>Gola Fek :– 16 Pound Through 16 Feet</li></h3>
                      <h1>Female</h1>
                      <h2>{`1.)`} Gen/OBC/Others:-</h2>
                      
                      <h3><li>Height :– 155CMS</li></h3>
                      <h3><li>Chest :– NA</li></h3>
                      <h3><li>Running :– 1 KM in 6 Minutes</li></h3>
                      <h3><li>High Jump :– 3 Feet</li></h3>
                      <h3><li>Long Jump :– 9 Feet</li></h3>
                      <h3><li>Gola Fek :– 12 Pound Through 10 Feet</li></h3>
                      </div>
                     
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Left Thumb Impression</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Download Admit Card</h2></div>
                        <div><a href="https://apply-bpssc.com/BPSSC_02_2023_WE_ADMITCARDS/applicationIndex" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Rejected Candidate List</h2></div>
                        <div><a href="https://bpssc.bih.nic.in/Notices/NB-2023-11-22-01.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Roll Wise Center List</h2></div>
                        <div><a href="https://bpssc.bih.nic.in/Notices/NB-2023-11-21-01.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Exam Notice / OMR Sample</h2></div>
                        <div><a href="https://apply-bpssc.com/BPSSC_SI_02_23/applicationIndex" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Re Upload Photo/Signature</h2></div>
                        <div><a href="https://apply-bpssc.com/BPSSC_SI_02_23/applicationIndex" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://apply-bpssc.com/BPSSC_SI_02_23/applicationIndex" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://bpssc.bih.nic.in/Advts/Advt-02-2023-SI.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://testbook.com/bihar-police-si/syllabus-exam-pattern" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://testbook.com/bihar-police-si/previous-year-papers" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=X1DrZZAeoh4" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.bpsc.bih.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div style={{display:"inline" ,textAlign:"center"}}><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingBottom:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                   
                </div>
             </div>
             <img src={a1} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default Bihar_Police_bpssc_sub_inspector